import { store } from '../store/configureStore';

const validRole = (auth, role) => {
    //console.log(auth, role, auth.role.indexOf(role));
    if (auth)
      if (auth._id && auth._id > 0)
        return (auth.role.indexOf(role) >= 0);
      else
        return false;
    else
      return false;
  }
  
function authToken() {
  //console.log("auth", store.getState().auth);
  return store.getState().auth.user?.token;
}

// header authentication functions
function authHeader(url) {
  // return auth header with jwt if user is logged in and request is to the api url
  const token = authToken();
  const isLoggedIn = !!token;
  const isApiUrl = url.startsWith(process.env.REACT_APP_API_URL);
  if (isLoggedIn && isApiUrl) {
      return { Authorization: `Bearer ${token}` };
  } else {
      return {};
  }
}

export {validRole, authToken, authHeader}