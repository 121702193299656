import { history, axiosClient } from '../_helpers';

export const signUp = (user) => ({
  type: "USER_SIGNUP",
  user
});

export const startSignup = (displayName, email, password) => {
  return async dispatch => {
    try {
      const response = await axiosClient.post(
        `${process.env.REACT_APP_API_URL}/users/signup`,
        {
            email,
            displayName,
            password
        }
      );

      if (response.data && response.data.Result)
      {
        const user = response.data.Data;
        localStorage.setItem("auth", JSON.stringify(user));
  
        dispatch(
          signUp(user)
        );
  
        history.navigate("/");
      }
      else
      {
        if (response.data && response.data.Message)
          throw new Error(response.data.Message);
        else
          throw new Error("Can't signup...");
      }

    } catch (e) {
      console.error(e);
      throw e.message;
  }
  };
};

export const changePassword = (user) => ({
  type: "USER_CHANGE",
  user
});

export const startChangePassword = (email, oldPassword, password) => {
  return async dispatch => {
    try {
      const response = await axiosClient.post(
        `${process.env.REACT_APP_API_URL}/users/change`,
        {
            email,
            oldPassword,
            password
        }
      );

      if (response.data && response.data.Result)
      {
        const user = response.data.Data;
        localStorage.setItem("auth", JSON.stringify(user));

        dispatch(
          changePassword(user)
        );

        history.navigate("/");
      }
      else
      {
        if (response.data && response.data.Message)
          throw new Error(response.data.Message);
        else
          throw new Error("Can't change password...");
      }
    } catch (e) {
        console.error(e);
        throw e.message;
    }
  };
};
