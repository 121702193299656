const postReducer = (state = {}, action) => {
  //console.log(action);

  switch (action.type) {
    case "POST_TRACKING":
      return {...state, 
        postViews: action.views
      };
    default:
      return state;
  }
};

export default postReducer;
