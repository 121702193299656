export const updatePostCategory = (postsArray) => {
  const postTypeOptions = [
    { value: 0, icon: 'fa-home', label: 'Others' },
    { value: 1, icon: 'fa-home', label: 'Journal Article' },
    { value: 2, icon: 'fa-home', label: 'Book Review' },
    { value: 3, icon: 'fa-home', label: 'Instructions' },
    { value: 4, icon: 'fa-home', label: 'Training & Events' },
    { value: 5, icon: 'fa-home', label: 'News' },
  ];

  console.log("postsArray", postsArray);
  if (postsArray){
    postsArray.forEach(function(element) 
    { 
      //console.log(postTypeOptions[element.postType || 0]);
      element.category = postTypeOptions[element.postType || 0].label;
        //console.log(element);

    });

    //console.log(postsArray[catId]);
    return postsArray;
  }

  //console.log(postsArray[catId]);
  return null;
};

export const sortPostsByDate = postsArray => {
  const newPostsArray = [].concat(postsArray);
  return newPostsArray.sort((a, b) => {
    if (a.createdAt > b.createdAt) {
      return -1;
    }
    if (a.createdAt < b.createdAt) {
      return 1;
    }
  });
};

export const sortCommentsByDate = commentsArray => {
  const newCommentsArray = [].concat(commentsArray);
  return newCommentsArray.sort((a, b) => {
    if (a.createdAt > b.createdAt) {
      return -1;
    }
    if (a.createdAt < b.createdAt) {
      return 1;
    }
  });
};
