import React, {useEffect} from "react";
import { connect } from "react-redux";
import moment from "moment";

import { sortCommentsByDate } from "../selectors/posts";
import { startSetComments, startClearComments } from '../actions/comments';

export const CommentList = (props) => {
  const [editing, setEditing] = React.useState(0);
  const [state, setState] = React.useState({ id: 0, title: "", comment: "", error: "" });
  //console.log(props);

  useEffect(() => {
    try {
      const fetchComments = async () => {
        await props.startSetComments(0);
      }

      if (!state.error && !props.comments)
        fetchComments()
          .catch(console.error);

    } catch (e) {
      console.error(e);
      setState({ ...state, error: e.message });
    }

  }, [props]);

  const renderError = () => {
    if (state.error) {
      return <div className="error-msg text-danger">{state.error}</div>;
    }
  };

  const renderComments = () => {
    if (props.comments && props.comments.result.length > 0) {
      return sortCommentsByDate(props.comments.result).map(comment => {
        return (
          (comment.Id > 0) && <li key={comment.Id} className="nav-item">
                        <a className={`nav-link ${comment.Status==0?"text-danger":""}`} href={`/posts/${comment.PostId}#c${comment.Id}`}>
                            {comment.Title} - <span className="text-muted" style={{fontFamily: "Open Sans, sans-serif", fontSize:"10pt"}}>
                              {comment.CreatedByName} ({moment(comment.CreatedOn).format('MM/DD/YYYY HH:mm:ss')})</span>
                        </a>
                    </li>  
        );
      });
    } else {
      return <p>No comments found!</p>;
    }
  };
  
    return (
      <div className={"sidebar-detached "}>
            <div className="sidebar">
                <div className="sidebar-content card hidden-md-down">
                    <div className="card-block">
                        <div id="sidebar" className="category-title">
                        <h4 className="box-title">Latest Comments</h4>
                        <ul className='side-menu m-0'>
                          {renderComments()}
                        </ul>
                        <div className="text-xs-right"><a href="/comments" className="link-to">View more &#62;&#62;</a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
  
}

const mapStateToProps = (state, props) => ({
  auth: state.auth.user,
  comments: state.comments ? state.comments.commentList : null,
});

export default connect(mapStateToProps,
  {startSetComments, startClearComments}
  )(CommentList);
