const tags2string = (tags, splitter = ';') => {
    //console.log(tags, typeof tags);
  
    if (tags)
      if (typeof tags === "string")
        return tags;
      else
        return tags.join(splitter);
    else
      return "";
  }
  
const string2tags = (strTag, splitter = ';') => {
    //console.log(strTag);
  
    if (strTag && strTag.length > 0)
        return strTag.split(splitter);
    else
      return [];
  }

export {tags2string, string2tags}