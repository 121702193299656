import App from "./App";
//import "./styles/styles.scss";
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/fonts.css';
import './css/layout.css';
import './css/app.css';
import './css/site.css';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from "./store/configureStore";
import { BrowserRouter } from 'react-router-dom';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <div>
    <React.StrictMode>
      <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
      </Provider>
    </React.StrictMode>
  </div>
);

