import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { startGetCategories } from '../actions/posts';
import PostAddNew from "./PostAddNew";
import { startAddPost, startClearPosts } from "../actions/posts";
import { validRole, metaTagGenerator } from '../_helpers';
import "../css/search.css";

import { useNavigate, createSearchParams } from 'react-router-dom';

const PageHeaderComponent = (props) => {
  const [state, setState] = useState({error: ''});
  const [value, setValue] = useState('');
  const [hasMounted, setHasMounted] = useState(false);
  
  const navigate = useNavigate();

  const menuItems = props.categories ? props.categories : [];

  useEffect(() => {

      async function fetchData() {
          try {
          if ((!props.categories || props.categories.length === 0) && !props.tag && !props.author) {
              await props.startGetCategories();

          }
          } catch (e) {
              setState({ error: e.message });
          }
      }
      if (!state.error)
        fetchData();

      setHasMounted(true);

    });

  useEffect(() => {
    const input = document.getElementById("search");
    const submit = (e) => {
      if (e.keyCode === 13) {
        handleClick();
      }
    };
    input.addEventListener("keyup", submit);

    return () => input.removeEventListener("keyup", submit);
  }, [value]);

  //console.log("tag", props.tag);
  if (props.tag)
    metaTagGenerator(props.tag ? "Mindsponge.info - " + props.tag : "Mindsponge.info - SM3D Knowledge Management Theory",
    props.tag ? "Mindsponge.info - Tag: " + props.tag : "Mindsponge.info - SM3D Knowledge Management Theory",
    props.tag ? "Mindsponge, Serendipity, " + props.tag : "Mindsponge, Serendipity, SM3D Knowledge Management Theory");
  else if (props.author)
    metaTagGenerator(props.author ? "Mindsponge.info - " + props.author : "Mindsponge.info - SM3D Knowledge Management Theory",
    props.author ? "Mindsponge.info - Author: " + props.author : "Mindsponge.info - SM3D Knowledge Management Theory",
    props.author ? "Mindsponge, Serendipity, " + props.author : "Mindsponge, Serendipity, SM3D Knowledge Management Theory");
  else if (menuItems.length > 0 && props.catId > 0)
    metaTagGenerator(props.catId > 0 ? "Mindsponge.info - " + menuItems[props.catId].label : "Mindsponge.info - SM3D Knowledge Management Theory",
      props.catId > 0 ? "Mindsponge.info - " + menuItems[props.catId].label : "Mindsponge.info - SM3D Knowledge Management Theory",
      props.catId > 0 ? "Mindsponge, Serendipity, " + menuItems[props.catId].label : "Mindsponge, Serendipity, SM3D Knowledge Management Theory");
  else
    metaTagGenerator("Mindsponge.info - SM3D Knowledge Management Theory",
      "Mindsponge.info - SM3D Knowledge Management Theory",
      "Mindsponge, Serendipity, SM3D Knowledge Management Theory");

  // if (hasMounted)
  // {
  //   (function() {
  //     var cx = 'aa49706905f5a35d2';

  //     //if (document.getElementById("gcse"))
  //     //  document.getElementById("gcse").remove();

  //     var ele = document.getElementById("gcse");
  //     if(ele === null){
  //       var gcse = document.createElement('script');
  //       gcse.id = "gcse";
  //       gcse.type = 'text/javascript';
  //       gcse.async = true;
  //       gcse.src = 'https://cse.google.com/cse.js?cx=' + cx;
  //       var s = document.getElementsByTagName('script')[0];
  //       s.parentNode.insertBefore(gcse, s);
  //     }
  //   })();
  // }

  const onSubmit = async post => {
    try {
      await props.startAddPost(post);
      await props.startClearPosts();

    } catch (e) {
      console.error(e.message);
      setState({ error: e.message });
    }
  };
  
  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleClick = () => {
    if (value.length > 0) {
      navigate({
        pathname: "/search",
        search: `?${createSearchParams({q: value})}`,
      });
    }
  };

  return (
    <div className="content-header row">
      <div className="content-header-left col-md-9 col-xs-12">
        {(props.categories && props.categories.length > 0 && !props.tag && !props.author) && validRole(props.auth,"editor") && <PostAddNew catId={props.catId} auth={props.auth} post={null} categories={menuItems} onSubmit={onSubmit} />}
        {(props.categories && props.categories.length > 0 && !props.tag && !props.author) && <h1 className="header-title mb-0">{menuItems[props.catId].label}</h1>}
        {(props.categories && props.categories.length > 0 && !props.tag && !props.author) && <div className="row breadcrumbs-top">
            <div className="breadcrumb-wrapper col-xs-12">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="/">Home</a>
                    </li>
                    {(props.categories && props.categories.length > 0) && <li className="breadcrumb-item active">
                      <a href={menuItems[props.catId].href}>{menuItems[props.catId].label}</a>                      
                    </li>}
                </ol>
            </div>
        </div>}
        {(props.categories && props.categories.length > 0 && props.tag) && <h1 className="header-title mb-0">Tag: {props.tag}</h1>}
        {(props.categories && props.categories.length > 0 && props.author) && <h1 className="header-title mb-0">Author: {props.author}</h1>}
      </div>
    
      <div className="content-header-right text-md-right col-md-3 col-xs-12">
        <div className="form-group">
          <div className="input-group ">
            <input name="search" id="search" className="search-input form-control" placeholder="Search…" maxLength="240" value={value} onChange={handleChange} />
            <button onClick={handleClick} className="btn-search"><svg width="13" height="13" viewBox="0 0 13 13"><title>search</title><path d="m4.8495 7.8226c0.82666 0 1.5262-0.29146 2.0985-0.87438 0.57232-0.58292 0.86378-1.2877 0.87438-2.1144 0.010599-0.82666-0.28086-1.5262-0.87438-2.0985-0.59352-0.57232-1.293-0.86378-2.0985-0.87438-0.8055-0.010599-1.5103 0.28086-2.1144 0.87438-0.60414 0.59352-0.8956 1.293-0.87438 2.0985 0.021197 0.8055 0.31266 1.5103 0.87438 2.1144 0.56172 0.60414 1.2665 0.8956 2.1144 0.87438zm4.4695 0.2115 3.681 3.6819-1.259 1.284-3.6817-3.7 0.0019784-0.69479-0.090043-0.098846c-0.87973 0.76087-1.92 1.1413-3.1207 1.1413-1.3553 0-2.5025-0.46363-3.4417-1.3909s-1.4088-2.0686-1.4088-3.4239c0-1.3553 0.4696-2.4966 1.4088-3.4239 0.9392-0.92727 2.0864-1.3969 3.4417-1.4088 1.3553-0.011889 2.4906 0.45771 3.406 1.4088 0.9154 0.95107 1.379 2.0924 1.3909 3.4239 0 1.2126-0.38043 2.2588-1.1413 3.1385l0.098834 0.090049z"></path></svg></button>
          </div>
            {/* <div className="gcse-searchbox-only" data-resultsurl="/search" data-newwindow="false" data-queryparametername="q"></div> */}
        </div>
      </div>      
    </div>
  );
};

const mapStateToProps = state => ({
  auth: state.auth.user,
  categories: state.categories
});

const mapDispatchToProps = dispatch => ({
  startGetCategories: () => dispatch(startGetCategories()),
  startAddPost: (post) => dispatch(startAddPost(post)),
  startClearPosts: () => dispatch(startClearPosts()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PageHeaderComponent);
