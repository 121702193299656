const commentsReducer = (state = {}, action) => {
  //console.log(state, action);

  switch (action.type) {
    case "SET_COMMENTS":
      return action.comments;
      case "SET_COMMENTLIST":
        return action.comments;
      case "CLEAR_COMMENTS":
      return null;
  default:
      return state;
  }
};

export default commentsReducer;
