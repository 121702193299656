import React from "react";
import { connect } from 'react-redux';

export const PrintFooter = (props) => {
  
    return (
        <footer className="footer footer-static footer-light">
            <hr className="border-1 border-top opacity-100"></hr>
        <p className="clearfix text-muted text-sm-center mb-0 px-2">
            <img src="/images/logo/logo_lab_s25.jpg" alt="Lab's logo" width="25" height="33" />&nbsp;
            <span className="d-xs-block d-md-inline-block">
                ©{(new Date()).getFullYear()} AISDL - Science Portal for the <a href="https://www.nature.com/articles/s41599-022-01034-6" target="_blank">SM3D Knowledge Management Theory</a>
            </span>
        </p>
        </footer>
    );
}

const mapStateToProps = state => ({
  auth: state.auth.user
});

export default connect(mapStateToProps)(PrintFooter);
