import React from "react";
import { connect } from "react-redux";
import { useState, useEffect } from 'react';
import { compose } from "redux";
import { useNavigate, useSearchParams, createSearchParams } from 'react-router-dom';

import SideBar from "./SideBar";
import PageHeader from "./PageHeader";
import { withRouter } from '../_helpers';
import { metaAdder, metaTagGenerator } from '../_helpers';

export const SearchPage = (props) => {
  const [state, setState] = useState({error: ''});
  const [value, setValue] = useState('');
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  //console.log(props);
  
  useEffect(() => {
    metaAdder('name="robots"', "noindex,nofollow");
    metaAdder('name="googlebot"', "noindex,nofollow");

    metaTagGenerator("Mindsponge.info - Search - SM3D Knowledge Management Theory",
      "Mindsponge.info - Search - SM3D Knowledge Management Theory",
      "Mindsponge, Serendipity, SM3D Knowledge Management Theory");

    window.scrollTo(0, 0)
  });

  useEffect(() => {
    const input = document.getElementById("search");
    const submit = (e) => {
      if (e.keyCode === 13) {
        var ele = document.getElementById("google-search-results");
        if(ele !== null)
          ele.innerHTML = "";

        handleClick();
      }
    };
    input.addEventListener("keyup", submit);

    return () => input.removeEventListener("keyup", submit);
  }, [value]);

const perform_google = function(querySearch){
  if (document.readyState === "complete") {
    window.google.search.cse.element.render({
        div: "google-search-results",
        tag: 'searchresults-only',
        gname: 'google-results-gname'
    });
    var element = window.google.search.cse.element.getElement('google-results-gname');

    if (querySearch && querySearch.length > 0)
    {
      element.innerHTML = "";
      element.execute(querySearch);
    }

    // for (const entry of searchParams.entries()) {
    //   const [param, paramValue] = entry;
    //   console.log(paramValue);
    //   //const currentParams = Object.fromEntries([...searchParams]);
    //   if (param === 'q')
    //   {
    //     var query = paramValue;
    //     element.execute(query);
    //   }
    // }
      
  }
};

const callback_google = function(){
  if (document.readyState === "complete") {
    for (const entry of searchParams.entries()) {
      const [param, paramValue] = entry;
      //console.log(paramValue);
      //const currentParams = Object.fromEntries([...searchParams]);
      if (param === 'q')
      {
        var query = paramValue;
        perform_google(query);
      }
    }
      
  }
};

(function(){
    // add the google custom stuff:
    var ele = document.getElementById("gcse");
    if(ele === null){
      var cx = 'aa49706905f5a35d2';
      var gcse = document.createElement('script');
      gcse.type = 'text/javascript';
      gcse.async = true;
      gcse.id = "gcse";
      //gcse.src = 'https://cse.google.com/cse.js?cx=' + cx;
      gcse.src = (document.location.protocol === 'https:' ? 'https:' : 'http:') + '//cse.google.com/cse.js?cx=' + cx;
      var s = document.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(gcse, s);
    }
})();

window.__gcse = {
  parsetags: 'explicit',
  callback: callback_google,
};

const handleChange = (e) => {
  setValue(e.target.value);
};

const handleClick = () => {
  if (value.length > 0) {
    perform_google(value);

    navigate({
      pathname: "/search",
      search: `?${createSearchParams({q: value})}`,
    });
  }
};

  return (
    <div>
      <div className="content-header row">
          <div className="content-header-left col-md-9 col-xs-12">
            <h1 className="lastestPost mb-0">Search Results</h1>
            <div className="row breadcrumbs-top">
                <div className="breadcrumb-wrapper col-xs-12">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <a href="/">Home</a>
                        </li>
                        <li className="breadcrumb-item active">
                          <a href="">Search Results</a>                      
                        </li>
                    </ol>
                </div>
            </div>
        </div>
        
        <div className="content-header-right text-md-right col-md-3 col-xs-12">
        <div className="form-group">
          <div className="input-group ">
            <input name="search" id="search" className="search-input form-control" placeholder="Search…" maxLength="240" value={value} onChange={handleChange} />
            <button onClick={handleClick} className="btn-search"><svg width="13" height="13" viewBox="0 0 13 13"><title>search</title><path d="m4.8495 7.8226c0.82666 0 1.5262-0.29146 2.0985-0.87438 0.57232-0.58292 0.86378-1.2877 0.87438-2.1144 0.010599-0.82666-0.28086-1.5262-0.87438-2.0985-0.59352-0.57232-1.293-0.86378-2.0985-0.87438-0.8055-0.010599-1.5103 0.28086-2.1144 0.87438-0.60414 0.59352-0.8956 1.293-0.87438 2.0985 0.021197 0.8055 0.31266 1.5103 0.87438 2.1144 0.56172 0.60414 1.2665 0.8956 2.1144 0.87438zm4.4695 0.2115 3.681 3.6819-1.259 1.284-3.6817-3.7 0.0019784-0.69479-0.090043-0.098846c-0.87973 0.76087-1.92 1.1413-3.1207 1.1413-1.3553 0-2.5025-0.46363-3.4417-1.3909s-1.4088-2.0686-1.4088-3.4239c0-1.3553 0.4696-2.4966 1.4088-3.4239 0.9392-0.92727 2.0864-1.3969 3.4417-1.4088 1.3553-0.011889 2.4906 0.45771 3.406 1.4088 0.9154 0.95107 1.379 2.0924 1.3909 3.4239 0 1.2126-0.38043 2.2588-1.1413 3.1385l0.098834 0.090049z"></path></svg></button>
          </div>
        </div>
        </div>      
      </div>

        <div className="content-detached content-left">
          <div className="content-body">
            <div id="google-search-results" className="gcse-searchresults-only mb-1"></div>
          </div>
        </div>

        <div className="sidebar-detached sidebar-right">
        <SideBar showAttr="d-none d-md-block" />
        </div>
    </div>
  );
};

export default compose(
  withRouter,
)(SearchPage);
