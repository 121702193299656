
const categoryUrl = (categories, post) => {
    if (categories && categories.length > 0)
    {
        const cat = categories.filter(option => 
            option.value === post.groupId);

        if (cat && cat.length > 0)
            return cat[0].href;
        else
            return "/";
    }
    else
        return "/";
}

export {categoryUrl}
