import { history, axiosClient } from '../_helpers';

export const getCategories = categories => ({
  type: "GET_CATEGORIES",
  categories
});

export const startGetCategories = () => {
  return async dispatch => {
    try {
      const response = await axiosClient.get(
        `${process.env.REACT_APP_API_URL}/categories`,
      );
      //console.log(response.data.Data);
      if (response.data && response.data.Result)
        dispatch(getCategories(response.data.Data));
      else
        throw new Error("Could not retrieve categories...");
    } catch (e) {
      //console.error(e.message);
      throw new Error("Could not retrieve categories...");
    }
  };
};

export const postTracking = views => ({
  type: "POST_TRACKING",
  views
});

export const startPostTracking = (postId, userId) => {
  return async dispatch => {
    try {
      const response = await axiosClient.post(
        `${process.env.REACT_APP_API_URL}/post/track/${postId}`,
        {userId: userId}
      );
      //console.log(response.data);
      if (response.status === 200)
        dispatch(postTracking(response.data));
      else
        throw new Error(`Could not retrieve views (${response.status}, ${response.data})...`);
    } catch (e) {
      console.error(e.message);
      //throw new Error("Could not retrieve views...");
    }
  };
};

export const getPost = post => ({
  type: "GET_POST",
  post
});

export const startGetPost = (id) => {
  return async dispatch => {
    try {
      const response = await axiosClient.get(
        `${process.env.REACT_APP_API_URL}/posts/${id}`,
      );
      //console.log(response.data);
      dispatch(getPost(response.data));
    } catch (e) {
      console.error(e.message);
      throw new Error("Could not retrieve post...");
    }
  };
};

export const setPosts = (posts) => ({
  type: "SET_POSTS",
  posts
});

export const setRelatedPosts = (posts) => ({
  type: "SET_RELATEDPOSTS",
  posts
});

export const setMostReadPosts = (posts) => ({
  type: "SET_MOSTREADS",
  posts
});


export const startSetPostsByCategory = (id, type, page) => {
  return async dispatch => {
    try {
      const response = await axiosClient.post(
        `${process.env.REACT_APP_API_URL}/posts`,
        {catId: id, postType: type, page: page}
      );
      //console.log("startSetPostsByCategory", id, page, response.data);
      if (id >= 0 && response.data)
      {
        let data = response.data;
        data.catId = id;
        data.preload = false;
        dispatch(setPosts(response.data));
      }
    } catch (e) {
      console.error(e.message);
      throw new Error("Could not retrieve posts...");
    }
  };
};

export const startSetPostsByTag = (tag, page) => {
  return async dispatch => {
    try {
      const response = await axiosClient.post(
        `${process.env.REACT_APP_API_URL}/posts`,
        {tag: tag, page: page}
      );
      //console.log("startSetPostsByCategory", id, page, response.data);
      if (tag && response.data)
      {
        let data = response.data;
        data.tag = tag;
        data.preload = false;
        dispatch(setPosts(response.data));
      }
    } catch (e) {
      console.error(e.message);
      throw new Error("Could not retrieve posts...");
    }
  };
};

export const startSetPostsByAuthor = (author, page) => {
  return async dispatch => {
    try {
      const response = await axiosClient.post(
        `${process.env.REACT_APP_API_URL}/posts`,
        {author: author, page: page}
      );
      //console.log("startSetPostsByCategory", id, page, response.data);
      if (author && response.data)
      {
        let data = response.data;
        data.author = author;
        data.preload = false;
        dispatch(setPosts(response.data));
      }
    } catch (e) {
      console.error(e.message);
      throw new Error("Could not retrieve posts...");
    }
  };
};

export const startSetRelatedPosts = (id) => {
  return async dispatch => {
    try {
      const response = await axiosClient.post(
        `${process.env.REACT_APP_API_URL}/relatedposts`,
        {postId: id}
      );
      //console.log("startSetPostsByCategory", id, page, response.data);
      if (id >= 0 && response.data)
      {
        let data = response.data;
        data.preload = false;
        dispatch(setRelatedPosts(response.data));
      }
    } catch (e) {
      console.error(e.message);
      throw new Error("Could not retrieve related posts...");
    }
  };
};

export const startSetMostReadPosts = (catId) => {
  return async dispatch => {
    try {
      const response = await axiosClient.post(
        `${process.env.REACT_APP_API_URL}/mostreadposts`,
        {catId: catId}
      );
      //console.log("startSetMostReadPosts", catId, response.data);
      if (response.data)
      {
        let data = response.data;
        data.preload = false;
        dispatch(setMostReadPosts(response.data));
      }
    } catch (e) {
      console.error(e.message);
      throw new Error("Could not retrieve related posts...");
    }
  };
};

export const clearPost = posts => ({
  type: "CLEAR_POST",
  posts
});

export const startClearPost = () => {
  return async dispatch => {
    try {
      dispatch(clearPost());
    } catch (e) {
      console.error(e.message);
      throw new Error("Could not clear post...");
    }
  };
};

export const clearPosts = posts => ({
  type: "CLEAR_POSTS",
  posts
});

export const startClearPosts = () => {
  return async dispatch => {
    try {
      dispatch(clearPosts());
    } catch (e) {
      console.error(e.message);
      throw new Error("Could not clear posts...");
    }
  };
};

export const addPost = post => ({
  type: "ADD_POST",
  post
});

export const startAddPost = post => {
  return async dispatch => {
    const { token } = JSON.parse(localStorage.getItem("auth"));
    try {
      //console.log(post);
      const response = await axiosClient({
        url: `${process.env.REACT_APP_API_URL}/posts/add`,
        method: "post",
        headers: {
          "x-auth": token
        },
        data: post
      });

      if (response.data && response.data.Result)
      {
        if (response.data.post && response.data.post._id > 0)
        {
          dispatch(addPost(response.data.post));

          history.navigate(`/posts/${response.data.post._id}/edit`)
        }
      }
    } catch (e) {
      console.error(e);
      throw new Error(e.message);
    }
  };
};

export const editPost = updates => ({
  type: "EDIT_POST",
  updates
});

export const startEditPost = (post, id) => {
  return async dispatch => {
    const { token } = JSON.parse(localStorage.getItem("auth"));
    try {
      const response = await axiosClient({
        url: `${process.env.REACT_APP_API_URL}/posts/${id}`,
        method: "patch",
        headers: {
          "x-auth": token
        },
        data: post
      });

      //console.log("data", response.data.post);
      if (response.data && response.data.Result)
        dispatch(editPost(response.data.post));
    } catch (e) {
      console.error(e);
      throw new Error(e.message);
    }
  };
};

export const deletePost = id => ({
  type: "DELETE_POST",
  id
});

export const startDeletePost = (id) => {
  return async dispatch => {
    //console.log(id);

    const { token } = JSON.parse(localStorage.getItem("auth"));
    try {
      const response = await axiosClient({
        url: `${process.env.REACT_APP_API_URL}/posts/${id}`,
        method: "delete",
        headers: {
          "x-auth": token
        }
      });

      if (response.data)
        dispatch(deletePost(id));
    } catch (e) {
      console.error(e);
      //throw new Error(e.response.data.error);
    }
  };
};

export const publishPost = id => ({
  type: "PUBLISH_POST",
  id
});

export const startPublishPost = (id) => {
  return async dispatch => {
    //console.log(id);

    const { token } = JSON.parse(localStorage.getItem("auth"));
    try {
      const response = await axiosClient({
        url: `${process.env.REACT_APP_API_URL}/posts/pub/${id}`,
        method: "post",
        headers: {
          "x-auth": token
        }
      });

      if (response.data)
        dispatch(publishPost(id));
    } catch (e) {
      console.error(e);
      //throw new Error(e.response.data.error);
    }
  };
};

export const unpublishPost = id => ({
  type: "PUBLISH_POST",
  id
});

export const startUnPublishPost = (id) => {
  return async dispatch => {
    //console.log(id);

    const { token } = JSON.parse(localStorage.getItem("auth"));
    try {
      const response = await axiosClient({
        url: `${process.env.REACT_APP_API_URL}/posts/unpub/${id}`,
        method: "post",
        headers: {
          "x-auth": token
        }
      });

      if (response.data)
        dispatch(unpublishPost(id));
    } catch (e) {
      console.error(e);
      //throw new Error(e.response.data.error);
    }
  };
};