import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { history, axiosWrapper } from '../_helpers';

// create slice

const name = 'auth';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, reducers, extraReducers });

// exports

export const authActions = { ...slice.actions, ...extraActions };
export const authReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
      user: localStorage.getItem('auth') ? JSON.parse(localStorage.getItem('auth')) : {},
      error: null
    }
}

function createReducers() {
    return {
        logout,
    };

    function logout(state) {
      //console.log(state);
        state.user = {};
        localStorage.removeItem('auth');
        //history.navigate('/login');
    }
}

function createExtraActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}/users`;

    return {
      startLogin: login(),
      startGoogleLogin: googleLogin(),
    };    

    function login() {
        return createAsyncThunk(
            `${name}/login`,
            async ({ email, password }) => await axiosWrapper.post(`${baseUrl}/login`, { email, password })
        );
    }

    function googleLogin() {
        return createAsyncThunk(
            `${name}/googlelogin`,
            async ({ token }) => await axiosWrapper.post(`${baseUrl}/googlelogin`, { token })
        );
    }
}

function createExtraReducers() {
    return {
        ...login(),
        ...googlelogin(),
    };

    function login() {
        var { pending, fulfilled, rejected } = extraActions.startLogin;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const user = action.payload;

                //console.log("user", user);
                
                if (user && user._id > 0)
                {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('auth', JSON.stringify(user));
                    state.user = user;

                    // get return url from location state or default to home page
                    const { from } = history.location.state || { from: { pathname: '/' } };
                    history.navigate(from);
                }
                else
                {
                    if (user && user.Message)
                        throw new Error(user.Message);
                    else
                        throw new Error("Can't change password...");
            
                }
            },
            [rejected]: (state, action) => {
                state.error = action.error;
            }
        };
    }

    function googlelogin() {
        var { pending, fulfilled, rejected } = extraActions.startGoogleLogin;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const user = action.payload;

                console.log("user", user);
                
                if (user && user._id > 0)
                {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('auth', JSON.stringify(user));
                    state.user = user;

                    // get return url from location state or default to home page
                    const { from } = history.location.state || { from: { pathname: '/' } };
                    history.navigate(from);
                }
                else
                {
                    if (user && user.Message)
                        throw new Error(user.Message);
                    else
                        throw new Error("Can't change password...");
            
                }
            },
            [rejected]: (state, action) => {
                state.error = action.error;
            }
        };
    }
}
