import {
    useLocation,
    useNavigate,
    useParams
  } from "react-router-dom";
  
export const withRouter = (Component) => {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    const params = useParams();

    const useBasePath = () => {
      const params = useParams();
      const location = useLocation();

      return Object.values(params).reduce(
          (path, param) => path.replace('/' + param, ''),
          location.pathname,
        );
    };

    const basePath = useBasePath();

    return (
      <Component
        {...props}
        router={{ location, navigate, params, basePath }}
      />
    );
  }

  return ComponentWithRouterProp;
}

export const history = {
    navigate: null,
    location: null
};