import React, { useEffect, useState }  from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { useNavigate } from 'react-router-dom';

import PostForm from "./PostForm";
import { startGetPost } from "../actions/posts";
import { startDeletePost } from "../actions/posts";
import { startEditPost, startClearPost } from "../actions/posts";

import { withRouter } from '../_helpers';
import CommentBox from "./CommentBox";


export const EditPostPage = (props) => {
  const [state, setState] = useState({error: ""});
  
  //const { id } = useParams();
  const navigate = useNavigate();

  if (!props.post && props.postId > 0) {
    try {        
      //console.log(props);

      const fetchData = async () => {
        await props.startGetPost(props.postId);
      }

      fetchData()
        .catch(console.error);

    } catch (e) {
      console.log(e);
      setState({ error: e.message });
    }
  }

  const renderError = () => {
    if (state.error) {
      return (
        <div className="error-msg alert alert-danger" role="alert">
          <p className="m-0">{state.error}</p>
        </div>
      );
    }
  };

  const onSubmit = async post => {
    try {
      await props.startEditPost(post, props.post._id);
      await props.startClearPost();

      navigate(`/posts/${props.post._id}`)

    } catch (e) {
      setState({ error: e.message });
    }
  };
  
    return (
      props.post && <div className="container post-item">
      <div className='card p-2'>
        {renderError()}

        <PostForm auth={props.auth} post={props.post} onSubmit={onSubmit} />

        <hr className="border-1 border-top opacity-100"></hr>
        <div className="mt-1">
          <i className="icon-tag"></i> tags: &nbsp;
          {props.post.tags.map(function (item) {
            return (
              <span key={item} className="ant-tag">
                <a href={"/tag/" + item} rel="category tag">{item}</a>
              </span>
            );
          })}
        </div>
      </div>
      <CommentBox
        postId={props.post._id}
        comments={props.comments}
      />
    </div>
    );
  
}

const mapStateToProps = (state, props) => {
  //console.log(state.post, props);

  return {
    auth: state.auth.user,
    categories: state.categories,
    postId: parseInt(props.router.params.id),
    post: state.post && state.post._id === parseInt(props.router.params.id) ? state.post : null,
  }
};

const mapDispatchToProps = dispatch => ({
  startGetPost: (id) => dispatch(startGetPost(id)),
  startClearPost: () => dispatch(startClearPost()),
  startDeletePost: id => dispatch(startDeletePost(id)),
  startEditPost: (post, id) => dispatch(startEditPost(post, id)),
});

export default compose(
  withRouter,
  connect(
  mapStateToProps,
  mapDispatchToProps)
)(EditPostPage);
