import React, { useEffect, useState, useRef }  from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { useNavigate } from 'react-router-dom';
import moment from "moment";

import CommentBox from "./CommentBox";
import { startGetPost, startDeletePost, startEditPost, startClearPost, startPostTracking, startPublishPost, startUnPublishPost, startClearPosts } from "../actions/posts";
import { startSetComments } from '../actions/comments';
import PostSettings from "./PostSettings";

import { withRouter, validRole, categoryUrl, metaTagGenerator, canonicalAdder, tags2string } from '../_helpers';

import CommentForm from "./CommentForm";
import PrintFooter from "./PrintFooter";
import PrintHeader from "./PrintHeader";

import SideBar from "./SideBar";
import AdsBanner from "./AdsBanner";

//import { useExternalScript } from "../_helpers/useExternalScript";

import ReactToPrint, { useReactToPrint } from "react-to-print";

export const PostItem = (props) => {
  const [state, setState] = useState({error: ""});
  const [viewLoaded, setView] = useState(false);
  const navigate = useNavigate();

  const [loading, setLoading] = React.useState(false);
  const [text, setText] = React.useState("");
  const componentRef = useRef(null);
  const onBeforeGetContentResolve = useRef(null);
  useEffect(() => {
    if (loading && onBeforeGetContentResolve.current) {
      onBeforeGetContentResolve.current(); // Resolves the Promise in `onBeforeGetContent`
    }
  }, [loading, onBeforeGetContentResolve]);

  //const { id } = useParams();

  //console.log(props);

  useEffect(() => {
    if (props.post)
    {
      var ele = document.getElementById("preloaded");
      if(ele !== null)
        ele.remove();

      if (!viewLoaded)
      {
        setView(true);
        props.startPostTracking(props.postId, props.auth._id);
      }
    }

    if (props.router.location.hash)
    {
      //console.log(props.router.location.hash);
      setTimeout(() => {
        const id = props.router.location.hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
    //else
    //  window.scrollTo(0, 0);
    const pathname = window.location.pathname;
    canonicalAdder(pathname);

  }, [props]);

  if (!props.post && props.postId > 0) {
    try {        

      const fetchData = async () => {
        await props.startGetPost(props.postId);
      }
  
      if (!state.error)
        fetchData()
          .catch(console.error);

    } catch (e) {
      console.error(e);
      this.setState({ error: e.message });
    }
  }

  if (props.post)
  {
    metaTagGenerator(props.post ? props.post.title : "Mindsponge.info - SM3D Knowledge Management Theory",
      props.post ? props.post.sapo.substring(0, 500) : "Mindsponge.info - SM3D Knowledge Management Theory",
      props.post ? tags2string(props.post.tags, ',') : "Mindsponge, Serendipity, SM3D Knowledge Management Theory",
      props.post ? `https://mindsponge.info/avatar/${props.post._id}/200/200` : "https://mindsponge.info/logo80.png",
      props.post.createdAt ? props.post.createdAt : new Date());  
  }

  // if (viewLoaded)
  // {
  //   (function() {
  //     //if (document.getElementById("mathjax"))
  //     //  document.getElementById("mathjax").remove();

  //     var ele = document.getElementById("mathjax");
  //     if(ele === null){
  //       var gcse = document.createElement('script');
  //       gcse.id = "mathjax";
  //       gcse.type = 'text/javascript';
  //       //gcse.async = true;
  //       gcse.src = 'https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-chtml.js';
  //       var s = document.getElementsByTagName('script')[0];
  //       s.parentNode.insertBefore(gcse, s);
  //     }

  //     //if (document.getElementById("polyfill"))
  //     //  document.getElementById("polyfill").remove();

  //     ele = document.getElementById("polyfill");
  //     if(ele === null){
  //       var gcse = document.createElement('script');
  //       gcse.id = "polyfill";
  //       gcse.type = 'text/javascript';
  //       //gcse.async = true;
  //       gcse.src = 'https://polyfill.io/v3/polyfill.min.js?features=es6';
  //       var s = document.getElementsByTagName('script')[0];
  //       s.parentNode.insertBefore(gcse, s);
  //     }

  //     //if (document.getElementById("mathjax-cfg"))
  //     //  document.getElementById("mathjax-cfg").remove();

  //     ele = document.getElementById("mathjax-cfg");
  //     if(ele === null){
  //       var gcse = document.createElement('script');
  //       gcse.id = "mathjax-cfg";
  //       gcse.type = 'text/javascript';
  //       gcse.innerHTML = "MathJax = {tex: {inlineMath: [['$', '$'], ['\\(', '\\)']]}};";
  //       var s = document.getElementsByTagName('script')[0];
  //       s.parentNode.insertBefore(gcse, s);
  //     }

  //   })();
  // }


// KATEX
 
    (function() {
      if (document.getElementById("mathjax-cfg"))
        document.getElementById("mathjax-cfg").remove();

      var ele = document.getElementById("mathjax-cfg");
      if(ele === null){
        var gcse = document.createElement('script');
        gcse.id = "mathjax-cfg";
        gcse.type = 'text/javascript';
        gcse.innerHTML = "if (document.getElementById('main-content')) renderMathInElement(document.getElementById('main-content'));";
        document.body.appendChild(gcse);
      }

    })();



  const onSubmit = async post => {
    try {
      await props.startEditPost(post, props.post._id);
      await props.startClearPosts();
      await props.startClearPost();

    } catch (e) {
      setState({ error: e.message });
    }
  };

  const doDelete = async post => {
    try {
      await props.startDeletePost(post._id);
      await props.startClearPosts();

      const groupUrl = categoryUrl(props.categories, post);
      //console.log("groupUrl", groupUrl);
      navigate(groupUrl);

    } catch (e) {
      setState({ error: e.message });
    }
  };

  const doPublish = async (e, post) => {
    const id = post._id;
    await props.startPublishPost(id);
    await props.startClearPosts();
    await props.startClearPost();
  
  };

  const doUnPublish = async (e, post) => {
    const id = post._id;
    await props.startUnPublishPost(id);
    await props.startClearPosts();
    await props.startClearPost();  
  };


  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called"); // tslint:disable-line no-console
  }, []);

  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called"); // tslint:disable-line no-console
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    //console.log("`onBeforeGetContent` called"); // tslint:disable-line no-console
    setLoading(true);
    setText("Loading content to print...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setLoading(false);
        setText("Content Updated!");
        resolve();
      }, 2000);
    });
  }, [setLoading, setText]);

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: (props.post ? props.post.title : "Mindsponge"),
    onBeforeGetContent: handleOnBeforeGetContent,
    onBeforePrint: handleBeforePrint,
    onAfterPrint: handleAfterPrint,
    removeAfterPrint: true
  });

  const renderEditButton = () => {
    if (validRole(props.auth, "admin")) {
      return (
        <ul className="nav navbar-nav float-xs-right">
          {(props.post.status === 0 && validRole(props.auth,"admin")) && <li className="nav-item">
              <button onClick={(e) => doPublish(e, props.post)} className="btn btn-outline-dark" title="Publish article">
                  <i className="fa fa-upload" /> 
              </button>
          </li>}
          {(props.post.status === 1 && validRole(props.auth,"admin")) && <li className="nav-item">
              <button onClick={(e) => doUnPublish(e, props.post)} className="btn btn-outline-dark" title="Unpublish article">
                  <i className="fa fa-download" /> 
              </button>
          </li>}
          {
          <li className="nav-item">
            <PostSettings auth={props.auth} post={props.post} categories={props.categories} onSubmit={onSubmit} />
          </li>
          }
          <li className="nav-item">
          <button onClick={() => navigate(`/posts/${props.post._id}/edit`)} className="btn btn-outline-dark" title="Edit article">
            <i className="fa fa-pencil-square-o" />
            </button>
          {/* <Link
            to={`/posts/${props.post._id}/edit`}
            className="btn btn-outline-dark article-button"
          >
            <i className="fa fa-pencil-square-o" />
          </Link> */}
          </li>
          {<li className="d-none d-md-block nav-item">
            <button onClick={handlePrint} className="btn btn-outline-dark" title="Print article">
                <i className="fa fa-print" />
            </button>
          </li>}
          {(props.post.locked !== 1) && <li className="nav-item">
            <button onClick={async () => await doDelete(props.post)} className="btn btn-outline-dark" title="Delete article">
                <i className="fa fa-trash-o" />
            </button>
          </li>}
        </ul>
      );
    }
    else
    {
      return (
        <ul className="d-none d-md-block nav navbar-nav float-xs-right">
          {<li className="nav-item">
            <button onClick={handlePrint} className="btn btn-outline-dark" title="Print article">
                <i className="fa fa-print" />
            </button>
          </li>}
        </ul>
      );
    }

  }

  const renderCommentForm = () => {
    if (props.auth._id) {
      return <CommentForm postId={props.postId} auth={props.auth} />;
    }
    return <div className="font-italic pt-1 mb-0">
      <div>
        <button className="btn btn-primary" disabled={true}>
          Add Comment
        </button>
      </div>
    You must be signed in to comment...</div>;
  };

  const renderPost = () => {
    //console.log("post", props.post);

    if (props.post) {
      return (
        (props.post.status === 1 || validRole(props.auth,"editor")) ? <div className="container post-item">
          {loading && text && <div className="alert alert-info">{text}</div>}
          <div className={'card p-2' + (loading ? " border-0" : "")} ref={componentRef} style={{ pageBreakAfter: 'always' }}>
            {loading && <PrintHeader post={props.post} />}
            {state.error && <div className="alert alert-danger">{state.error}</div>}
            <h1 className="article-title">
              {!loading && renderEditButton()}
              {(props.post.status === 0) && <span>[DRAFT] </span>}
              {props.post.title}
            </h1>
            {!loading && <div className="entry-meta ml-0 mt-0">
                <span>
                  <span className="fa fa-user-o"></span>
                  <a href={`/author/${props.post.author}`} title={"Post by " + props.post.author} rel="author">{props.post.author}</a>
                </span>
                {props.categories && <span>
                  <span className="fa fa-book"></span>
                  <a href={props.categories[props.post.groupId] ? props.categories[props.post.groupId].href : "#"} rel="category tag">{props.post.category}</a>
                </span>}
                <span>
                  <span className="fa fa-clock-o"></span>
                  <time className="entry-date published" dateTime={props.post.createdAt}>{moment(props.post.createdAt).format('MM/DD/YYYY HH:mm:ss')}</time>
                </span>
                <span>
                  <span className="fa fa-eye"></span>
                  <span rel="category tag">{props.views}</span>
                  {/* <a rel="category tag">{props.post.viewCount}</a> */}
                </span>
            </div>}
            <div className="article-main" id="main-content" dangerouslySetInnerHTML={{ __html: props.post.body }}></div>
            {!loading && <hr className="border-1 border-top opacity-100"></hr>}
            {props.post.tags && !loading && <div className="mt-1">
              <i className="icon-tag"></i> tags: &nbsp;
              {props.post.tags.map(function (item) {
                return (
                  <span key={item} className="ant-tag">
                    <a href={"/tag/" + item} rel="category tag">{item}</a>
                  </span>
                );
              })}
            </div>}
            {(props.post.locked !== 1) && !loading && renderCommentForm()}
            {loading && <PrintFooter />}
          </div>
          {
          (props.post.locked !== 1) && <CommentBox
            postId={props.post._id}
            comments={props.comments}
          />
          }
        </div> : <div className="alert alert-danger">The article is not existed or you have no permission to view this content!</div>
      );
    }
    return (
      <div>Loading...</div>
      // <div className="container post-item">
      //   <div className="alert alert-danger" role="alert">
      //     Could not retrieve post...
      //     <Link to="/" className="alert-link">
      //       Click here
      //     </Link>{" "}
      //     to go back to dashboard.
      //   </div>
      // </div>
    );
  }

  return (
      <div>
          {renderPost()}

          {props.post && <div className="card">
          <div className="related-article">
            RELATED POSTS
          </div>
            {props.post.related && <ul className="row pl-2 pr-1">
            {props.post.related.map(function (item) {
                return (
                  <li key={item._id} className="related-item col">
                    <div className="related-hero">
                      <img src={`/avatar/${item._id}/100/100.png`} className="row" />
                    </div>
                    <a href={"/posts/" + item._id} rel="category post" >{item.title}</a>
                    <div className="related-meta">{moment(item.createdAt).format('MM-DD-YYYY HH:mm:ss')} / By {item.author}</div>
                  </li>
                );
            })}
            </ul>}
          </div>}

      </div>
    );
  
}

const mapStateToProps = (state, props) => {
  //console.log(state, props);

  return {
    auth: state.auth.user,
    views: state.tracking && state.tracking.postViews ? parseInt(state.tracking.postViews) : 0,
    categories: state.categories,
    postId: props.postId && props.postId > 0 ? props.postId : parseInt(props.router.params.id),
    post: state.post && (state.post._id === parseInt(props.router.params.id) || state.post._id === props.postId) ? state.post : null,
    //comments: state.comments
  }
};

export default compose(
  withRouter,
  connect(
  mapStateToProps,
  { startGetPost, startDeletePost, startSetComments, startEditPost, startClearPost, startPostTracking, startPublishPost, startUnPublishPost, startClearPosts })
)(PostItem);
