const postReducer = (state = null, action) => {
  switch (action.type) {
    case "CLEAR_POST":
      return null;
    case "GET_POST":
      //console.log(state, action.post);
      return action.post;
    case "ADD_POST":
      //return [...state, action.post];
      return action.post;
    case "EDIT_POST":
      return action.updates;
      // return state.map(post => {
      //   if (post._id === action.updates._id) {
      //     return { ...post, ...action.updates };
      //   } else {
      //     return post;
      //   }
      // });
    case "DELETE_POST":
      return null;
      // return state.filter(post => {
      //   if (post._id !== action.id) {
      //     return post;
      //   }
      // });
    default:
      return state;
  }
};

export default postReducer;
