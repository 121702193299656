import React, {useMemo} from "react";
import { connect } from "react-redux";
import moment from "moment";
import JoditEditor from "jodit-react";

import { sortCommentsByDate } from "../selectors/posts";
import { startSetComments, startDeleteComment, startPublishComment, startAddComment, startClearComments } from '../actions/comments';
import { validRole } from '../_helpers';

export const CommentBox = (props) => {
  const [editing, setEditing] = React.useState(0);
  const [state, setState] = React.useState({ id: 0, title: "", comment: "", error: "" });

  const editor = React.useRef(null);
  const config1 = {
    useSearch: false,
    spellcheck: false,
    enter: "br",
    defaultMode: "1",
    toolbarAdaptive: false,
    toolbarSticky: false,
    showCharsCounter: false,
    showWordsCounter: true,
    showXPathInStatusbar: false,
    askBeforePasteHTML: false,
    askBeforePasteFromWord: false,
    minHeight: 200,
    maxHeight: 500,
    minWidth: null,
    buttons:
      "paragraph,bold,strikethrough,underline,italic,|,superscript,subscript,|,ul,ol,|,|,font,fontsize,brush,,link,|,align,undo,redo,image",
    editorCssClass: "alic",
    uploader: {
      insertImageAsBase64URI: true
    },
    placeHolder: "",
    controls: {
      fontsize: {
        list: [
          "8",
          "9",
          "10",
          "11",
          "12",
          "14",
          "16",
          "18",
          "24",
          "30",
          "36",
          "48",
          "60",
          "72",
          "96",
          "100"
        ]
      },
      font: {
        command: "fontname",
        list: {
          "": "Default",
          "'Open Sans',sans-serif": "Open Sans",
          "Helvetica,sans-serif": "Helvetica",
          "Arial,Helvetica,sans-serif": "Arial",
          "Georgia,serif": "Georgia",
          "Impact,Charcoal,sans-serif": "Impact",
          "Tahoma,Geneva,sans-serif": "Tahoma",
          "'Times New Roman',Times,serif": "Times New Roman",
          "Verdana,Geneva,sans-serif": "Verdana"
        }
      }
    }
  };

  const config = useMemo(
    () => (config1),
    []
  );

  //console.log("CommentBox", props.comments);
  if (!props.comments && props.postId > 0) {
    try {        

      const fetchComments = async () => {
        await props.startSetComments(props.postId);
      }

      if (!state.error)
        fetchComments()
          .catch(console.error);      

    } catch (e) {
      console.error(e);
      setState({ ...state, error: e.message });
    }
  }

  const doDelete = async (e, id) => {
    await props.startDeleteComment(id);
    //await props.startClearComments();

  };

  const doPublish = async (e, id) => {
    await props.startPublishComment(id);
    //await props.startClearComments();

  };

  const doEdit = (e, comment) =>
  {
    setState({id: comment.Id, title: comment.Title, comment: comment.Body, error: "" });
    setEditing(comment.Id);
  };

  const doSave = (e, id) =>
  {
    const { title } = state;
    //console.log(id, title, state);
    if (title.length < 8 || title.length > 128) {
      setState({ ...state, error: "Title must be between 8 and 128 characters." });
    } else {
      setState({ ...state, error: "" });
      props.startAddComment({Id: id, postId: props.postId, title: state.title, body: state.comment, createdBy: props.auth._id });
      //props.startClearComments();
      setState({ title: "", comment: "", error: "" });
      setEditing(0);
    }
  };

  const setTitle = e => {
    setState({...state, title: e });
  };

  const setBody = e => {
    setState({...state, comment: e });
  };

  const renderError = () => {
    if (state.error) {
      return <div className="error-msg text-danger">{state.error}</div>;
    }
  };

  const renderButtons = (comment) => {
      return <ul className="nav navbar-nav float-xs-right">
      {
      (props.auth._id && comment.Status === 0 && editing !== comment.Id) && <li className="nav-item">
          <button onClick={(e) => doPublish(e, comment.Id)} className="btn btn-outline-dark" title="Publish comment">
              <i className="fa fa-upload" />
          </button>
          </li>
      }
      {
      (props.auth._id && editing !== comment.Id) && <li className="nav-item">
          <button onClick={(e) => doEdit(e, comment)} className="btn btn-outline-dark" title="Edit comment">
              <i className="fa fa-pencil-square-o" />
          </button>
          </li>
      }
      {
      (props.auth._id && editing === comment.Id) && <li className="nav-item">
          <button onClick={(e) => doSave(e, comment.Id)} className="btn btn-outline-dark" title="Save comment">
              <i className="fa fa-floppy-o" />
          </button>
          </li>
      }
      {
      (props.auth._id && editing === comment.Id) && <li className="nav-item">
          <button onClick={() => setEditing(0)} className="btn btn-outline-dark" title="Cancel">
              <i className="fa fa-times" />
          </button>
          </li>
      }
      {
      (props.auth._id && editing !== comment.Id) && <li className="nav-item">
          <button onClick={async (e) => await doDelete(e, comment.Id)} className="btn btn-outline-dark" title="Delete comment">
              <i className="fa fa-trash-o" />
          </button>
          </li>
      }
    </ul>;
  };

  const renderComments = () => {
    //console.log(props.comments, props.comments ? props.comments.result.length : null);
    if (props.comments && props.comments.result.length > 0) {
      return sortCommentsByDate(props.comments.result).map(comment => {
        return (
          (comment.Status === 1 || validRole(props.auth,"editor") || (props.auth && props.auth._id == comment.CreatedBy)) && <section id={"item" + comment.Id} key={comment.Id} className="row mr-0">
            <div className='col-md-2'>
                  <div className="entry-meta ml-1 mt-0">
                      <span>
                        <a id={`c${comment.Id}`} href={`#c${comment.Id}`} title={`Commented by ${comment.CreatedByName}`} className="comment-Id">#{comment.Id}</a>
                      </span>
                      <span>
                        <span className="fa fa-user-o"></span>
                        Commented by:&nbsp;
                        <a href={`/author/${comment.CreatedBy}`} title={`Commented by ${comment.CreatedByName}`} className="comment-author">{comment.CreatedByName}</a>
                      </span>
                      <span>
                        <span className="fa fa-clock-o"></span>
                        <time className="entry-date published" dateTime={comment.CreatedOn}>{moment(comment.CreatedOn).format('MM/DD/YYYY HH:mm:ss')}</time>
                      </span>
                  </div>
    
            </div>
            <div className="col card row-item comment-card">
              <div className="card-header comment-card-header">
                <h2 className="comment-title">
                    {editing !== comment.Id && comment.Title}
                    {editing !== comment.Id && renderButtons(comment)}
                    {(props.auth._id && editing === comment.Id) && <div className="row">
                      <div className="col-md-10">
                      <input type="text" className="form-control col-md-8" placeholder="Title" value={state.title}
                      onChange={e => setTitle(e.target.value)}
                    /></div><div className="col-md-2">{renderButtons(comment)}</div>
                    </div>}
                </h2>
              </div>
              <div className="card-body">
              {
                (props.auth._id && editing === comment.Id) && renderError()
              }
              {(editing !== comment.Id) && <div className="comment-main" dangerouslySetInnerHTML={{ __html: comment.Body }}></div>}
              {(editing === comment.Id) && <JoditEditor
                ref={editor}
                  value={state.comment}
                  config={config}
                  tabIndex={1} // tabIndex of textarea
                  onBlur={newContent => setBody(newContent)} // preferred to use only this option to update the content for performance reasons
                  onChange={newContent => {}}
                  //onChange={newContent => setBody(newContent)}
              />}
              </div>
            </div>
          </section>
  
        );
      });
    } else {
      return <p></p>;
    }
  };
  
    return (
      <div>
        <ul className="list-group-flush pl-0">{renderComments()}</ul>
      </div>
    );
  
}

const mapStateToProps = (state, props) => ({
  auth: state.auth.user,
  comments: state.comments && state.comments.postId === props.postId ? state.comments.comments : null,
});

export default connect(mapStateToProps,
  {startSetComments, startClearComments, startDeleteComment, startAddComment, startPublishComment}
  )(CommentBox);
