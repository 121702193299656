import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import validator from "validator";
import { useNavigate } from 'react-router-dom';

import { authActions } from "../reducers/auth";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import { googleLogout } from '@react-oauth/google';
import { metaAdder, metaTagGenerator, canonicalAdder } from '../_helpers';

export function LoginPage(props) {  
  const [state, setState] = useState({email:"", password:"",error:props.auth.error});
  const navigate = useNavigate();

  const google_client_id = process.env.REACT_APP_GOOGLE_ID;

  useEffect(() => {
    metaAdder('name="google-signin-client_id"', google_client_id);
    metaAdder('name="robots"', "noindex,nofollow");
    metaAdder('name="googlebot"', "noindex,nofollow");

    metaTagGenerator("Mindsponge.info - Login - SM3D Knowledge Management Theory",
      "Mindsponge.info - Login - SM3D Knowledge Management Theory",
      "Mindsponge, Serendipity, SM3D Knowledge Management Theory");

    const pathname = window.location.pathname;
    canonicalAdder(pathname);
  
    window.scrollTo(0, 0)
  });

  const onEmailChange = e => {
    //console.log(e.target.value);

    setState({ ...state, email: e.target.value }, () => {
      !validator.isEmail(state.email)
        ? setState({ ...state, error: "Please  enter a valid email" })
        : setState({ ...state, error: "" });
    });
  };
  const onPasswordChange = e => {
    setState({ ...state, password: e.target.value }, () => {
      validator.isEmpty(state.password)
        ? setState({ ...state, error: "Please enter your password" })
        : setState({ ...state, error: "" });
    });
  };
  const onSubmit = async e => {
    e.preventDefault();
    try {
      const { email, password } = state;
      await props.startLogin(email, password);
      //props.history.push("/");
    } catch (e) {
      //console.error(e);
      return setState({ ...state, error: e.message });
    }
  };
  const isDisabled = () => {
    return !state.error &&
      validator.isEmail(state.email) &&
      !validator.isEmpty(state.password)
      ? false
      : true;
  };
  const renderError = () => {
    if (state.error) {
      return (
        <p className="error-msg alert alert-danger" role="alert">
          {state.error}
        </p>
      );
    }
  };

  return (
    <div className='signin-container'>
      <div className='card signin-box'>
        <div className='card-header'>
          <h3>Sign In</h3>
        </div>
        <div className='card-body'>
          {renderError()}
          <form onSubmit={onSubmit}>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                className="form-control"
                type="text"
                name="email"
                onChange={onEmailChange}
                autoFocus
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <input
                className="form-control"
                type="password"
                name="password"
                onChange={onPasswordChange}
              />
            </div>
            <div className="form-group d-grid gap-2">
              <button
                className="btn btn-primary btn-block"
                disabled={isDisabled()}
              >
                Login
              </button>
            </div>
            <div className='text-muted'>
            <small>Don't have an account? Registering <a href="/signup">here</a> for a free account will provide you with access to breaking news services, alerts on the latest research and more.</small>
            </div>
          </form>
          {google_client_id && <div className="alternate-signin-container">
            <div id="or-separator" className="or-separator mt-12 snapple-seperator">
              <span className="or-text">or</span>
            </div>
            <div className="d-grid gap-2">
            <GoogleOAuthProvider clientId={google_client_id}>
              <GoogleLogin
                onSuccess={async (credentialResponse) => {
                  console.log(credentialResponse);

                  const token = credentialResponse["credential"];

                  await props.startGoogleLogin(token);                
                }}
                onError={() => {
                  console.error('Login Failed');
                  setState({...state, error: "Login Failed"});
                  googleLogout();
                }}
              />
            </GoogleOAuthProvider>
            </div>
          </div>}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  //console.log(state.post, props);

  return {
    auth: state.auth,
  }
};

const mapDispatchToProps = dispatch => ({
  startLogin: (email, password) => dispatch(authActions.startLogin({email, password})),
  startGoogleLogin: (token) => dispatch(authActions.startGoogleLogin({token})),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginPage);
