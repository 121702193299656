import moment from "moment";

const metaDuplicated = (queryProperty, value) => {
  let metaList = document.querySelectorAll(`meta[${queryProperty}]`);

  if (metaList) {
    for (let meta of metaList) {
      if (meta.content === value)
        return true;
    }
  }

  return false;
}

const metaAdder = (queryProperty, value, repeat = false) => {
    // Get an element if it exists already
    let element = document.querySelector(`meta[${queryProperty}]`);

    //var content = element && element.content;
    //console.log(content, value);
  
    // Check if the element exists
    if (element && !repeat) {
      // If it does just change the content of the element
      element.setAttribute("content", value);
    } else {
      // It doesn't exist so lets make a HTML element string with the info we want
      element = `<meta ${queryProperty} content="${value}" />`;
  
      // And insert it into the head
      document.head.insertAdjacentHTML("beforeend", element);
    }
  }

const metaTagGenerator = (title, description, keywords, image = "https://mindsponge.info/logo80.png", pubdate = null) => {
  if (keywords && keywords.length > 0)
  {
    metaAdder('name="keywords"', keywords);
  }

  if (title && title.length > 0)
  {
    document.title = title;
    metaAdder('property="og:title"', title)
    metaAdder('itemprop="name"', title)
    metaAdder('name="twitter:title"', title);
  }

  if (description && description.length > 0)
  {
    metaAdder('name="description"', description);
    metaAdder('property="og:description"', description)
    metaAdder('itemprop="description"', description)
    document.getElementsByTagName('meta')["twitter:description"].content = description;
  }

  if (pubdate)
  {
    metaAdder('name="dc.title"', title);
    metaAdder('name="dc.description"', description);
    metaAdder('name="dc.date"', moment(pubdate).format('yyyy-MM-DD'));
    metaAdder('name="citation_title"', title);

    if (keywords && keywords.length > 0)
    {
      const keymap = keywords.split(",");
      
      keymap.map((keyword) => {
        //console.log(keyword, metaDuplicated('name="dc.subject"', keyword));
        if (!metaDuplicated('name="dc.subject"', keyword))
          metaAdder('name="dc.subject"', keyword, true);
      });
    }
  }

  if (image)
  {
    metaAdder('name="og:image"', image);
    metaAdder('property="og:image"', image);
    metaAdder('property="og:image:alt"', title);
  }
}

const canonicalAdder = (path) => {
  // Get an element if it exists already
  let element = document.querySelector('meta[name="canonical"]');

  if (element) {
    element.remove();
  }

  element = document.querySelector('link[rel="canonical"]');

  if (element) {
    element.remove();
  }
  
  element = `<link rel="canonical" href="https://mindsponge.info${path}" />`;

  // And insert it into the head
  document.head.insertAdjacentHTML("beforeend", element);
};

export {metaAdder, metaTagGenerator, canonicalAdder}