import React from "react";
import { connect } from "react-redux";
import { useState, useEffect } from 'react';
import { compose } from "redux";
import { useNavigate, useSearchParams } from 'react-router-dom';

import SideBar from "./SideBar";
import PageHeader from "./PageHeader";
import AdsBanner from "./AdsBanner";
import { withRouter, validRole, stripHtml, canonicalAdder } from '../_helpers';
import { startSetComments, startPublishComment, startUnPublishComment, startDeleteComment, startClearComments } from '../actions/comments';

import moment from 'moment';

import ReactPaginate from 'react-paginate';

export const CommentPage = (props) => {
  const [isLoading, setLoading] = useState(false); //State for the loading indicator
  const startLoading = () => setLoading(true);
  const stopLoading = () => setLoading(false);

  const [state, setState] = useState({error: ''});
  const navigate = useNavigate();

  let page = props.page;

  const [searchParams] = useSearchParams();
  for (const entry of searchParams.entries()) {
    const [param, value] = entry;
    //const currentParams = Object.fromEntries([...searchParams]);
    if (param === 'page')
      page = parseInt(value);
  }
  page = page > props.pageCount ? props.pageCount : page;
  page = page < 1 ? 1 : page;

  //const tag = props.router.params.tag;

  //const comments = props.comments && props.comments[props.catId] ? props.comments[props.catId].result : [];

  //console.log("CommentPage", props.currentPage, props.page, page, props, props.comments, (props.comments == null || props.currentPage !== page));

  useEffect(() => {
      async function fetchData() {
        try {
          if (props.comments == null || props.currentPage !== page || (props.preload && validRole(props.auth,"editor"))) {
            startLoading();
            await props.startSetComments(page);
          }
        } catch (e) {
          console.error(e);
          setState({ error: e.message });
        }
      }
      if (!state.error)
        fetchData();

      const pathname = window.location.pathname;
      canonicalAdder(pathname);

      window.scrollTo(0, 0);
    }, [props, page]);

  const handleFetchStatus = () => {
    if (state.error) {
      return (
        <p className="text-monospace alert-danger text-center">
          {state.error}
        </p>
      );
    } else {
      return (
        <div className="loader-container">
          <p className="text-monospace loading-text text-center">
            Fetching comments...
          </p>
          <p className="text-monospace loading-text text-center">
            Please wait...
          </p>
        </div>
      );
    }
  };

  const doDelete = async (e, comment) => {
    const id = comment.Id;
    await props.startDeleteComment(id);
    await props.startClearComments();
  
  };

  const doPublish = async (e, comment) => {
    const id = comment.Id;
    console.log(id, props.startPublishComment);
    await props.startPublishComment(id);
    await props.startClearComments();
  
  };

  const doUnPublish = async (e, comment) => {
    const id = comment.Id;
    await props.startUnPublishComment(id);
    await props.startClearComments();
  
  };

  const doEdit = (e, comment) =>
  {
    navigate(`/comments/${comment._id}/edit`);
  };

  const renderComments = () => 
  {
    if (props.comments && props.comments.result.length >= 1)
      return props.comments.result.map(comment => {
        return (
          ((comment.Status === 1 && props.auth._id === comment.CreatedBy) || validRole(props.auth,"editor")) && <div key={comment.Id} className="card">
            <div className="card-header">
                <h2 className="comment-title">
                {((comment.Status === 0 && (validRole(props.auth,"editor") || props.auth._id == comment.CreatedBy)) || validRole(props.auth,"admin")) && <ul className="nav navbar-nav float-xs-right">
                      {(comment.Status === 0 && validRole(props.auth,"admin")) && <li className="nav-item">
                          <button onClick={(e) => doPublish(e, comment)} className="btn btn-outline-dark" title="Publish article">
                              <i className="fa fa-upload" /> 
                          </button>
                      </li>}
                      {(comment.Status === 1 && validRole(props.auth,"admin")) && <li className="nav-item">
                          <button onClick={(e) => doUnPublish(e, comment)} className="btn btn-outline-dark" title="Unpublish article">
                              <i className="fa fa-download" /> 
                          </button>
                      </li>}
                      <li className="nav-item">
                          <button onClick={async (e) => await doDelete(e, comment)} className="btn btn-outline-dark" title="Delete article">
                              <i className="fa fa-trash-o" />
                          </button>
                      </li>
                    </ul>
                  }
                  {(comment.Status === 0) && <span style={{fontSize: "20pt"}}>[DRAFT] </span>}
                  <a href={`/posts/${comment.PostId}#c${comment.Id}`} className="title" rel="comment">
                    {comment.Title}
                  </a>
                </h2>
                <div className="text-muted">Commented at: <a href={`/posts/${comment.PostId}`} className="link-to">{comment.PostTitle}</a></div>
            </div>
            <div className="card-body">
            <div className="entry-meta ml-0 mt-0">
                <span>
                  <span className="fa fa-user-o"></span>
                  <a href="#" title={`Constributed by ${comment.CreatedByName}`} rel="author">{comment.CreatedByName}</a>
                </span>
                <span>
                  <span className="fa fa-clock-o"></span>
                  <time className="entry-date published" dateTime={comment.CreatedOn}>{moment(comment.CreatedOn).format('MM/DD/YYYY HH:mm:ss')}</time>
                </span>
            </div>

              <div className="row-sapo">
              <div className="sapo">
                  {comment.Body && stripHtml(comment.Body).length > 300 ? stripHtml(comment.Body).substring(0, 300) + "... ": stripHtml(comment.Body)}
                  {comment.Body && stripHtml(comment.Body).length > 300 && <div><a href={`/posts/${comment.PostId}#c${comment.Id}`} className="link-to" rel="comment">
                    Read more &#62;&#62;
                  </a></div>}
              </div>

              </div>
              <span className="badge badge-info">
                
              </span>
            </div>
          </div>
        );
      })
    else
    return handleFetchStatus();
  }

  const pagginationHandler = (page) => {
    //console.log("page", page, props);

    const currentPath = props.router.basePath;
    const currentQuery = props.router.params;
    currentQuery.page = page.selected + 1;

    //console.log(`${currentPath}`);

    navigate(`${currentPath}/${currentQuery.page}`)

    // navigate({
    //   pathname: currentPath,
    //   search: `?${createSearchParams({page: currentQuery.page})}`,
    // });
  };

  //console.log("catId", props.comments[catId]);

  return (
    <div>
        

        <div className="content-detached content-left">
          <div className="content-body">
            {props.comments && props.comments.result && props.comments.result.length > 0 && renderComments()}
            <div className="d-flex justify-content-center">
            {(props.pageCount > 1) && <ReactPaginate
              previousLabel={"«"}
              nextLabel={"»"}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={props.pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={pagginationHandler}
              containerClassName={'pagination'}
              pageClassName="page-item"
              previousClassName="page-item"
              nextClassName="page-item"
              pageLinkClassName="page-link"
              previousLinkClassName="page-link"
              nextLinkClassName="page-link"
              activeClassName="active"
              forcePage={page-1}
              //initialSelected={props.pageCount}
            />}
            </div>            
          </div>
        </div>
        <div className="sidebar-detached sidebar-right">
          <SideBar showAttr="d-none d-md-block" />

          <AdsBanner Link="/posts/101" Image="/images/bmf.webp" Title="" />

          <AdsBanner Link="/posts/98" Image="/images/kingfisher.webp" Title="" />
        </div>
    </div>
  );
};

//export default HomePage;

const mapStateToProps = (state, props) => {
  //console.log("state", state, props, props.router.params.page);

  return {
    auth: state.auth.user,
    page: props.router.params.page ? parseInt(props.router.params.page) : 1,
    totalCount: state.comments && state.comments.commentList && state.comments.commentList.meta ? state.comments.commentList.meta.totalCount : 0,
    pageCount: state.comments && state.comments.commentList && state.comments.commentList.meta ? state.comments.commentList.meta.pageCount : 0,
    currentPage: state.comments && state.comments.commentList && state.comments.commentList.meta ? state.comments.commentList.meta.currentPage : 0,
    perPage: state.comments && state.comments.commentList && state.comments.commentList.meta ? state.comments.commentList.meta.perPage : 0,
    comments: state.comments ? state.comments.commentList : null,
    preload: state.comments ? state.comments.preload : false
  }
};

const mapDispatchToProps = dispatch => ({
  startSetComments: (page) => dispatch(startSetComments(0, page)),
  startPublishComment: (id) => dispatch(startPublishComment(id)),
  startUnPublishComment: (id) => dispatch(startUnPublishComment(id)),
  startDeleteComment: (id) => dispatch(startDeleteComment(id)),
  startClearComments: () => dispatch(startClearComments()),
});

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(CommentPage);
