import React, { useState } from "react";
import { Link } from "react-router-dom";
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { authActions } from "../reducers/auth";
import { validRole } from '../_helpers';

export const Header = (props) => {
  //console.log("auth", props.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const renderLinks = () => {
    if (!props.auth || !props.auth._id) {
      return (
          <li className="nav-item d-sm-inline-block">
            <Link to="/signup" className="nav-link">
              Sign Up
            </Link>
          </li>
      );
    }
  };
  
  const renderProfileButton = () => {
    if (props.auth && props.auth._id) {
      return (
        <li className="nav-item d-sm-inline-block">
        <Link to="/profile" className="nav-link">
          Profile
        </Link>
      </li>
  );
    }
  };
  const renderSignOutButton = () => {
    if (props.auth && props.auth._id) {
      return (
        <li className="nav-item">
          <span
            name="signout"
            onClick={onSubmit}
            className="nav-link"
            //className="btn btn-secondary btn-sm"
          >
            Sign Out
          </span>
        </li>
      );
    }
    else
    {
      return (
        <li className="nav-item d-sm-inline-block">
        <Link to="/signin" className="nav-link">
          Sign In
        </Link>
      </li>
      );
    }
  };
  function onSubmit() {
        dispatch(authActions.logout());
        navigate("/");
    }
  
    return (
      <nav className="d-none d-md-block header-navbar navbar navbar-with-menu navbar-static-top navbar-dark navbar-shadow navbar-brand-center">
        <div className="navbar-wrapper">
          <div className="navbar-container container center-layout">
            <a className="navbar-brand" key="home" href="/">
                <img alt="Mindsponge logo" src="/images/logo/logo-light.png" width="186" height="37" data-expand="/images/logo/logo-dark.png" data-collapse="/images/logo/logo-light-sm.png" className="brand-logo" />
            </a>
            
            <ul className="nav navbar-nav float-xs-right d-none d-lg-block">
              <li className="nav-item d-sm-inline-block">
                <a href="/products" className="nav-link">
                  <i className="fas fa-pencil-alt" /> AISDL Products
                </a>
              </li>
              <li className="nav-item d-sm-inline-block">
                <a href="/about" className="nav-link">
                  <i className="fas fa-pencil-alt" /> About Us
                </a>
              </li>
              {renderLinks()}
              {renderProfileButton()}
              {renderSignOutButton()}
            </ul>
          </div>
        </div>
      </nav>
    );
}

const mapStateToProps = state => ({
  auth: state.auth.user
});

export default connect(mapStateToProps)(Header);
