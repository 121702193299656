import React, { useState } from "react";
import { compose } from "redux";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

import { startClearPosts, startPublishPost, startUnPublishPost, startDeletePost } from '../actions/posts';

import { withRouter, validRole } from '../_helpers';

export function PostsList (props) {
  const [state, setState] = useState({error: ''});
  const navigate = useNavigate();
  //console.log("props", props);

  const handleFetchStatus = () => {
    if (state.error) {
      return (
        <p className="text-monospace alert-danger text-center">
          {state.error}
        </p>
      );
    } else {
      return (
        <div className="loader-container">
          <p className="text-monospace loading-text text-center">
            ...
          </p>
        </div>
      );
    }
  };
  const renderThumbnail = (post) => {
    if (post.thumbnail) {
      //return <img src={post.thumbnail} className="img-fluid post-image" alt={`${post.title}`} />;
      return <img src={`/avatar/${post._id}.webp`} className="img-fluid post-image" alt={`${post.title}`} />;
    }
  }

  const doDelete = async (e, post) => {
    const id = post._id;
    await props.startDeletePost(id);
    await props.startClearPosts();
  
  };

  const doPublish = async (e, post) => {
    const id = post._id;
    await props.startPublishPost(id);
    await props.startClearPosts();
  
  };

  const doUnPublish = async (e, post) => {
    const id = post._id;
    await props.startUnPublishPost(id);
    await props.startClearPosts();
  
  };

  const doEdit = (e, post) =>
  {
    navigate(`/posts/${post._id}/edit`);
  };
  
  return (
        <div className="row">
          <div className="col">
            <div className="card-columns">
              {props.posts && props.posts.length >= 1
                ? props.posts.map(post => {
                    return (
                      (post.status === 1 || validRole(props.auth,"editor")) && <div key={post._id} className="card">
                        <div className="card-header">
                            <h2 className="article-title">
                            {((post.status === 0 && validRole(props.auth,"editor")) || validRole(props.auth,"admin")) && <ul className="nav navbar-nav float-xs-right">
                                  {(post.status === 0 && validRole(props.auth,"admin")) && <li className="nav-item">
                                      <button onClick={(e) => doPublish(e, post)} className="btn btn-outline-dark" title="Publish article">
                                          <i className="fa fa-upload" /> 
                                      </button>
                                  </li>}
                                  {(post.status === 1 && validRole(props.auth,"admin")) && <li className="nav-item">
                                      <button onClick={(e) => doUnPublish(e, post)} className="btn btn-outline-dark" title="Unpublish article">
                                          <i className="fa fa-download" /> 
                                      </button>
                                  </li>}
                                  <li className="nav-item">
                                      <button onClick={(e) => doEdit(e, post)} className="btn btn-outline-dark" title="Edit article">
                                          <i className="fa fa-pencil-square-o" />
                                      </button>
                                  </li>
                                  <li className="nav-item">
                                      <button onClick={async (e) => await doDelete(e, post)} className="btn btn-outline-dark" title="Delete article">
                                          <i className="fa fa-trash-o" />
                                      </button>
                                  </li>
                                </ul>
                              }
                              {(post.status === 0) && <span style={{fontSize: "20pt"}}>[DRAFT] </span>}
                              <a href={`/posts/${post._id}`} className="title" rel="post">
                                {post.title}
                              </a>
                            </h2>
                        </div>
                        <div className="card-body">
                        <div className="entry-meta ml-0 mt-0">
                            <span>
                              <span className="fa fa-user-o"></span>
                              <a href={`/author/${post.author}`} title={`Constributed by ${post.author}`} rel="author">{post.author}</a>
                            </span>
                            {/* {props.categories && <span>
                            <span className="fa fa-book"></span>
                            <a href={props.categories[post.groupId] ? props.categories[post.groupId].href : "#"} rel="category tag">{post.category}</a>
                          </span>} */}
                            <span>
                              <span className="fa fa-clock-o"></span>
                              <time className="entry-date published" dateTime={post.createdAt}>{moment(post.createdAt).format('MM/DD/YYYY HH:mm:ss')}</time>
                            </span>
                        </div>

                          <div className="row-sapo">
                          <div className="hero">
                            {renderThumbnail(post)}
                          </div>
                          <div className="sapo">
                              {post.sapo.substring(0, 500) + '...'}
                              <div className="text-xs-right btn-read position-absolute bottom-0 end-0">
                              <a
                                href={`/posts/${post._id}`}
                                key={post._id}
                                className="btn btn-sm btn-outline-primary">
                                Read More
                              </a>
                              </div>
                          </div>

                          </div>
                          <span className="badge badge-info">
                            
                          </span>
                        </div>
                        <div className="card-footer bg-white pb-1">
                          tags: &nbsp;
                          {post.tags.map(function (item) {
                              return (
                                <span key={item} className="ant-tag">
                                  <a href={"/tag/" + item} rel="category tag">{item}</a>
                                </span>
                              );
                            })}
                        </div>
                      </div>
                    );
                  })
                : handleFetchStatus()}
            </div>
          </div>
        </div>
    );
  
}

const mapStateToProps = (state, props) => ({
  auth: state.auth.user,
  categories: state.categories,
  page: props.router.params.page ? parseInt(props.router.params.page) : 1,
  //posts: !props.posts && state.posts && state.posts[props.catId]? updatePostCategory(state.posts[props.catId].result) : []
});

const mapDispatchToProps = dispatch => ({
  startDeletePost: (id) => dispatch(startDeletePost(id)),
  startPublishPost: (id) => dispatch(startPublishPost(id)),
  startUnPublishPost: (id) => dispatch(startUnPublishPost(id)),
  startClearPosts: () => dispatch(startClearPosts()),  
});

export default compose(
  withRouter,
  connect(
  mapStateToProps,
  mapDispatchToProps)
)(PostsList);
