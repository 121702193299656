import React, { useEffect }  from "react";
import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';

// import ReactGA from 'react-ga';
// const TRACKING_ID = "UA-234722664-1"; // OUR_TRACKING_ID
// ReactGA.initialize(TRACKING_ID);

import { history, canonicalAdder } from './_helpers';

import NavMobile from "./components/NavMobile";
import Header from "./components/Header";
import Footer from "./components/Footer";
import AdminRoute from "./routers/AdminRoute";
import PrivateRoute from "./routers/PrivateRoute";
import PublicRoute from "./routers/PublicRoute";
import TagPage from "./components/TagPage";
import AuthorPage from "./components/AuthorPage";
import HomePage from "./components/HomePage";
import CommentPage from "./components/CommentPage";
import ProductPage from "./components/ProductPage";
import SignupPage from "./components/SignupPage";
import LoginPage from "./components/LoginPage";
import AddPostPage from "./components/AddPostPage";
import PostItem from "./components/PostItem";
import PostEdit from "./components/PostEdit";
import ProfilePage from "./components/ProfilePage";
import SearchPage from "./components/SearchPage";
import Test from "./components/TestComponent";
import NotFoundPage from "./components/NotFoundPage";

function App() {
    // init custom history object to allow navigation from 
    // anywhere in the react app (inside or outside components)
    history.navigate = useNavigate();
    history.location = useLocation();

    useEffect(() => {
        const pathname = window.location.pathname;

        canonicalAdder(pathname);
    }, []);

    // useEffect(() => {
    // //     const script = document.createElement('script');
      
    // //     script.id = "BMF_LOG_TRACKING";
    // //     script.src = `/js/bmf.api.tracking.js?sq=${Date.now()}&lt=1&tk=1&nid=1`;
    // //     script.async = true;
      
    // //     document.body.appendChild(script);
      
    // //     return () => {
    // //       document.body.removeChild(script);
    // //     }
    //     (function() {
    //     var cx = 'aa49706905f5a35d2';

    //     var ele = document.getElementById("gcse");
    //     if(ele === null){
    //         var gcse = document.createElement('script');
    //         gcse.id = "gcse";
    //         gcse.type = 'text/javascript';
    //         gcse.async = true;
    //         gcse.src = 'https://cse.google.com/cse.js?cx=' + cx;
    //         var s = document.getElementsByTagName('script')[0];
    //         s.parentNode.insertBefore(gcse, s);
    //     }
    //     })();
    // }, []);

    return (
        <div>
            <Header />
            <NavMobile />
            <div className="app-content container center-layout mt-2">
                <div className="content-wrapper">
                    <div className="content-body">
                        <div className="container">
                            <Routes>
                                {/* <Route path="/"
                                        element={
                                            <PrivateRoute>
                                                <HomePage />
                                            </PrivateRoute>
                                        }
                                    /> */}
                                <Route index element={<HomePage catId={0} />} />
                                <Route path="/about" element={<PostItem postId={1} />} />
                                <Route path="/terms" element={<PostItem postId={2} />} />
                                <Route path="/policy" element={<PostItem postId={3} />} />
                                <Route path="/profile" element={<ProfilePage />} />
                                <Route path="/search" element={<SearchPage />} />
                                <Route path="/author/:author" element={<AuthorPage />} />
                                <Route path="/author/:author/:page" element={<AuthorPage />} />
                                <Route path="/tag/:tag" element={<TagPage />} />
                                <Route path="/tag/:tag/:page" element={<TagPage />} />
                                <Route path="/home" element={<HomePage catId={0} />} />
                                <Route path="/home/:page" element={<HomePage catId={0} />} />
                                <Route path="/resources" element={<HomePage catId={1} />} />
                                <Route path="/resources/:page" element={<HomePage catId={1} />} />
                                <Route path="/outcomes" element={<HomePage catId={2} />} />
                                <Route path="/outcomes/:page" element={<HomePage catId={2} />} />
                                <Route path="/projects" element={<HomePage catId={3} />} />
                                <Route path="/projects/:page" element={<HomePage catId={3} />} />
                                <Route path="/events" element={<HomePage catId={4} />} />
                                <Route path="/events/:page" element={<HomePage catId={4} />} />
                                <Route path="/credentials" element={<HomePage catId={5} />} />
                                <Route path="/credentials/:page" element={<HomePage catId={5} />} />
                                <Route path="/news" element={<HomePage catId={6} />} />
                                <Route path="/news/:page" element={<HomePage catId={6} />} />
                                <Route path="/worldview" element={<HomePage catId={8} />} />
                                <Route path="/worldview/:page" element={<HomePage catId={8} />} />
                                <Route path="/products" element={<HomePage catId={7} postType={2} />} />
                                <Route path="/products/:page" element={<HomePage catId={7} postType={2} />} />
                                <Route path="/signup" exact element={<SignupPage/>} />
                                <Route path="/signin" exact element={<LoginPage/>} />
                                <Route path="/posts/:id" exact element={<PostItem />} />
                                <Route path="/addpost" element={<AddPostPage/>} />
                                <Route path="/posts/:id/edit" element={<PostEdit />} />
                                <Route path="/comments" element={<CommentPage />} />
                                <Route path="/comments/:page" element={<CommentPage />} />
                                <Route path="/test" element={<Test />} />
                                <Route path="/notfound" element={<NotFoundPage status={404} />} />
                                <Route path="*" element={<Navigate replace to="/notfound"  />} />
                                {/* <Route path="*" element={<Navigate to="/notfound" />} /> */}
                            </Routes>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
    </div>
    );
}

export default App;
