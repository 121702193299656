import React, {useEffect} from "react";
import { connect } from "react-redux";

import { startSetComments } from '../actions/comments';

export const AdsBanner = (props) => {
  // const [editing, setEditing] = React.useState(0);
  // const [state, setState] = React.useState({ id: 0, title: "", banner: "", error: "" });
  //console.log(props);

  const renderAds = () => {
    if (props.ads) {
        return (
          <div key={props.ads.id} className="nav-item">
            <a className="nav-link" href={props.Link || props.ads.url}>
                <img src={props.Image || props.ads.banner} className="img-thumbnail" width="300" height="441" />
            </a>
          </div>  
        );
    } else {
      return <p></p>;
    }
  };
  
    return (
      // <div className={"sidebar-detached sidebar-right d-none d-md-block "}>
      <div className={"sidebar-detached"}>
            <div className="sidebar">
                {renderAds()}
            </div>
        </div>
    );
  
}

const mapStateToProps = (state, props) => ({
  auth: state.auth.user,
  ads: {id: 1, title:"title", banner:"/images/bmf.webp", url:"/posts/101"}
});

export default connect(mapStateToProps,
  {startSetComments}
  )(AdsBanner);
