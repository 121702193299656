import axios from "axios";
import { authToken } from './auth-helper';

const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'content-type': 'application/json',
    },
    //paramsSerializer: (params) => queryString.stringify(params),
  });
  
axiosClient.interceptors.request.use(async (config) => {
    const customHeaders = {};
  
    //const { token } = JSON.parse(localStorage.getItem("auth"));
    // get from state
    const token = authToken();
    if (token) {
      // customHeaders.Authorization = token;
      // or using x-auth
      customHeaders["x-auth"] = token;
    }
  
    return {
      ...config,
      headers: {
        ...customHeaders,  // auto attach token
        ...config.headers, // but you can override for some requests
      }
    };
  });
  
export {axiosClient};