import React, {useMemo} from "react";
import { connect } from "react-redux";
import JoditEditor from "jodit-react";

import { startClearComments, startAddComment } from "../actions/comments";

const CommentForm = (props) => {
  const [editing, setEditing] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const [state, setState] = React.useState({ title: "", comment: "", error: "" });

  const editor = React.useRef(null);
  // const config = {
  //   useSearch: false,
  //   spellcheck: false,
  //   enter: "br",
  //   defaultMode: "1",
  //   toolbarAdaptive: false,
  //   toolbarSticky: false,
  //   showCharsCounter: false,
  //   showWordsCounter: true,
  //   showXPathInStatusbar: false,
  //   askBeforePasteHTML: false,
  //   askBeforePasteFromWord: false,
  //   minHeight: 200,
  //   maxHeight: 500,
  //   minWidth: null,
  //   buttons:
  //     "paragraph,bold,strikethrough,underline,italic,|,superscript,subscript,|,ul,ol,|,|,font,fontsize,brush,,link,|,align,undo,redo,image",
  //   editorCssClass: "alic",
  //   uploader: {
  //     insertImageAsBase64URI: true,
  //   },
  //   placeHolder: "",
  //   controls: {
  //     fontsize: {
  //       list: [
  //         "8",
  //         "9",
  //         "10",
  //         "11",
  //         "12",
  //         "14",
  //         "16",
  //         "18",
  //         "24",
  //         "30",
  //         "36",
  //         "48",
  //         "60",
  //         "72",
  //         "96",
  //         "100"
  //       ]
  //     },
  //     font: {
  //       command: "fontname",
  //       list: {
  //         "": "Default",
  //         "'Open Sans',sans-serif": "Open Sans",
  //         "Helvetica,sans-serif": "Helvetica",
  //         "Arial,Helvetica,sans-serif": "Arial",
  //         "Georgia,serif": "Georgia",
  //         "Impact,Charcoal,sans-serif": "Impact",
  //         "Tahoma,Geneva,sans-serif": "Tahoma",
  //         "'Times New Roman',Times,serif": "Times New Roman",
  //         "Verdana,Geneva,sans-serif": "Verdana"
  //       }
  //     }
  //   }
  // };

  const config = useMemo(
    () => ({
      useSearch: false,
      spellcheck: false,
      enter: "br",
      defaultMode: "1",
      toolbarAdaptive: false,
      toolbarSticky: false,
      showCharsCounter: false,
      showWordsCounter: true,
      showXPathInStatusbar: false,
      askBeforePasteHTML: false,
      askBeforePasteFromWord: false,
      minHeight: 200,
      maxHeight: 500,
      minWidth: null,
      buttons:
        "paragraph,bold,strikethrough,underline,italic,|,superscript,subscript,|,ul,ol,|,|,font,fontsize,brush,,link,|,align,undo,redo,image",
      editorCssClass: "alic",
      uploader: {
        insertImageAsBase64URI: true,
      },
      placeHolder: "",
      controls: {
        fontsize: {
          list: [
            "8",
            "9",
            "10",
            "11",
            "12",
            "14",
            "16",
            "18",
            "24",
            "30",
            "36",
            "48",
            "60",
            "72",
            "96",
            "100"
          ]
        },
        font: {
          command: "fontname",
          list: {
            "": "Default",
            "'Open Sans',sans-serif": "Open Sans",
            "Helvetica,sans-serif": "Helvetica",
            "Arial,Helvetica,sans-serif": "Arial",
            "Georgia,serif": "Georgia",
            "Impact,Charcoal,sans-serif": "Impact",
            "Tahoma,Geneva,sans-serif": "Tahoma",
            "'Times New Roman',Times,serif": "Times New Roman",
            "Verdana,Geneva,sans-serif": "Verdana"
          }
        }
      }
    }),
    []
  );

  const renderError = () => {
    if (state.error) {
      return <div className="error-msg text-danger">{state.error}</div>;
    }
  };
  const onSubmit = async e => {
    e.preventDefault();

    setSaving(true);

    const { title } = state;
    if (title.length < 8 || title.length > 128) {
      setState({ ...state, error: "Title must be between 8 and 128 characters." });
    } else {    
      setState({ ...state, error: "" });
      await props.startAddComment({Id: 0, postId: props.postId, title: state.title, body: state.comment, createdBy: props.auth._id });
      //await props.startClearComments();
      setState({ title: "", comment: "", error: "" });
    }

    setSaving(false);
  };
  
  const setTitle = e => {
    setState({...state, title: e.target.value });
  };

  const setBody = e => {
    setState({...state, comment: e });
  };

  const isDisabled = () => {
    return (props.auth._id && props.auth._id > 0)
      ? false
      : true;
  };

    return (
        <div className='row-item form-group mb-0'>
          {renderError()}
          {(!editing) && <button onClick={() => {if (props.auth._id && props.auth._id > 0) setEditing(true);}} className="btn btn-primary" disabled={isDisabled()}>
                Add Comment
          </button>}
          {(editing) && <div className="row">
            <div className="col">
              <input type="text" className="form-control" placeholder="Title" value={state.title}
              onChange={e => setTitle(e)}
                />
            </div>
            <div className='col'>
              <button onClick={(e) => onSubmit(e)} className="btn btn-outline-primary mr-1" disabled={saving}>
                <i className="fa fa-floppy-o" /> Save
              </button>
              <button onClick={() => setEditing(false)} className="btn btn-outline-danger">
                <i className="fa fa-times" /> Cancel
              </button>
            </div>
          </div>}
          {(editing) && <JoditEditor
            	ref={editor}
                value={state.comment}
                config={config}
                tabIndex={1} // tabIndex of textarea
                onBlur={newContent => setBody(newContent)} // preferred to use only this option to update the content for performance reasons
                onChange={newContent => {}}
                //onChange={newContent => setBody(newContent)}
          />}
        </div>

    );
  
}

const mapDispatchToProps = dispatch => ({
  startAddComment: (comment) => dispatch(startAddComment(comment)),
  startClearComments: () => dispatch(startClearComments())
  
});

export default connect(
  null,
  mapDispatchToProps
)(CommentForm);
