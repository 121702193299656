const mostreadsReducer = (state = null, action) => {
  //console.log(state, action);

  switch (action.type) {
    case "SET_MOSTREADS":
      // return {
      //     ...state.posts,
      //     [action.posts.catId]: action.posts.data
      //   }
      
      //posts: [...state.arr, action.posts]
      
      return action.posts;
    case "CLEAR_MOSTREADS":
      return null;
      // return {
      //   ...state.posts,
      //   [action.posts.catId]: null
      // }
  default:
      return state;
  }
};

export default mostreadsReducer;
