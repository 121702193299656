import React from "react";
import { Link } from "react-router-dom";
import { useEffect } from 'react';
import { metaAdder, metaTagGenerator, canonicalAdder } from '../_helpers';

const NotFound = () => {
    useEffect(() => {
        metaAdder('name="robots"', "noindex,nofollow");
        metaAdder('name="googlebot"', "noindex,nofollow");
    
        metaTagGenerator("Mindsponge.info - 404 - SM3D Knowledge Management Theory",
          "Mindsponge.info - 404 - SM3D Knowledge Management Theory",
          "Mindsponge, Serendipity, SM3D Knowledge Management Theory");

        const pathname = window.location.pathname;
        canonicalAdder(pathname);
      
        window.scrollTo(0, 0)
      });
    
    return (
    
        <div className="not-found" style={{position: "relative"}}>
            <img
            src="/images/book-with-missing-page.png"
            alt="not-found"
            />
            <Link to="/" className="btn btn-info link-home">
            &#60;&#60; Go Home
            </Link>
        </div>
    );

}

export default NotFound;
