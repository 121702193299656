import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { authActions } from "../reducers/auth";
import { startGetCategories } from '../actions/posts';
import { validRole } from '../_helpers';

export const NavMobile = (props) => {
  const [isNavExpanded, setIsNavExpanded] = useState(false)
  const [state, setState] = useState({error: ''});

  useEffect(() => {
    async function fetchData() {
        try {
        if (!props.categories || props.categories.length === 0) {
            await props.startGetCategories();

        }
        } catch (e) {
            setState({ error: e.message });
        }
    }
    fetchData();

  }, [props.categories]);

  //console.log("auth", props.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const GotoUrl = (url) =>
  {
    navigate(url);
    setIsNavExpanded(false);
  }

  const renderLinks = () => {
    if (validRole(props.auth,"admin")) {
      return (
        <li className="nav-item d-sm-inline-block">
          <Link to="/addpost" className="nav-link">
            <i className="fas fa-pencil-alt" /> Add Post
          </Link>
        </li>
      );
    } else if (!props.auth || !props.auth._id) {
      return (
          <li className="nav-item d-sm-inline-block">
            <Link to="/signup" className="nav-link">
              Sign Up
            </Link>
          </li>
      );
    }
  };
  const renderSignOutButton = () => {
    if (props.auth && props.auth._id > 0) {
      return (
        <div className="d-grid gap-2">
          <button
            name="signout"
            onClick={onSubmit}
            className="btn btn-primary btn-sm"
          >
            <span className={"fa fa-sign-out mr-1"}></span> Log out
          </button>
          </div>
      );
    }
  };
  const renderProfileButton = () => {
    if (props.auth && props.auth._id > 0) {
      return (
        <div className="d-grid gap-2 mb-1">
          <button
            name="signout"
            onClick={() => GotoUrl("/profile")}
            className="btn btn-secondary btn-sm"
          >
            <span className={"fa fa-user mr-1"}></span> Profile
          </button>
          </div>
      );
    }
  };
  const renderSignInButton = () => {
    if (!(props.auth && props.auth._id > 0)) {
      return (
        <div className="d-grid gap-2">
          <button
              name="signin"
              onClick={() => GotoUrl("/signin")}
              className="btn btn-primary btn-sm"
            >
              <span className={"fa fa-user mr-1"}></span> Log in
            </button>
        </div>
      );
    }
  };
  function onSubmit() {
        dispatch(authActions.logout());
        navigate("/");
        setIsNavExpanded(false);
    }
  
    //console.log(props.categories);
    let menuItems = [];
    if (props.categories && props.categories.length > 0)
    {
        menuItems = props.categories
            .filter((linkConfig) => linkConfig)
            .map(({ icon, label, href, postCount, inmenu }) => {
                return (
                    (inmenu > 0) && <li key={href} className="nav-item">
                        <span className="nav-link" onClick={() => GotoUrl(href)}>
                            <span className={"fa mr-1 " + icon}></span>
                            {label} <span className="tag tag-success tag-pill">{postCount}</span>
                        </span>
                    </li>
                );
            });
    }
    return (
      <div className="sticky-top">
      <nav className="d-md-none header-navbar navbar navbar-with-menu navbar-static-top navbar-dark navbar-shadow navbar-brand-center">
        <div className="navbar-wrapper">
          <div className="navbar-container container center-layout">
            <Link className="navbar-brand" key="home" to="/">
                <img alt="Mindsponge logo" src="/images/logo/logo-light.png" width="186" height="37" data-expand="/images/logo/logo-dark.png" data-collapse="/images/logo/logo-light-sm.png" className="brand-logo" />
            </Link>
            
            <button className="navbar-toggler float-xs-right" type="button" onClick={() => {
                setIsNavExpanded(!isNavExpanded);
              }}>
              <span className="navbar-toggler-icon"></span>
            </button>

          </div>
        </div>        
      </nav>
      {isNavExpanded && <div className={"d-md-none "}>
          <div className="sidebar">
              <div className="sidebar-content card">
                  <div className="card-block">
                      {renderSignInButton()}
                      <hr className="p-0 m-0" />
                      <ul className='side-menu m-0'>
                          { menuItems }
                          <li className="nav-item d-sm-inline-block">
                          <span className="nav-link" onClick={() => GotoUrl("/products")}>
                            <i className="fas fa-pencil-alt" /> AISDL Products
                          </span>
                          </li>
                          <li className="nav-item d-sm-inline-block">
                            <span className="nav-link" onClick={() => GotoUrl("/about")}>
                              <i className="fas fa-pencil-alt" /> About Us
                            </span>
                          </li>
                      </ul>
                      {renderProfileButton()}
                      {renderSignOutButton()}
                  </div>
              </div>
          </div>
        </div>
      }
      </div>
    );
}

const mapStateToProps = state => ({
  auth: state.auth.user,
  categories: state.categories
});

const mapDispatchToProps = dispatch => ({
  startGetCategories: () => dispatch(startGetCategories())
});

export default connect(mapStateToProps, mapDispatchToProps)(NavMobile);
