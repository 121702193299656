import React, {useState} from 'react';
import { connect } from 'react-redux';
import validator from 'validator';

import ErrorsList from './ErrorsList';
import { startChangePassword } from '../actions/auth';

const SignupPage = (props) => {
  const [state, setState] = useState({displayName: props.auth.displayName,
  email: props.auth.email,
  oldPassword: '',
  password: '',
  passwordConfirm: '',
  errors: []});

  const onDisplayNameChange = e => {
    setState({ ...state, displayName: e.target.value });
  };
  const onEmailChange = e => {
    setState({ ...state, email: e.target.value });
  };
  const onOldPasswordChange = e => {
    setState({ ...state, oldPassword: e.target.value });
  };
  const onPasswordChange = e => {
    setState({ ...state, password: e.target.value });
  };
  const onPasswordConfirmChange = e => {
    setState({ ...state, passwordConfirm: e.target.value });
  };
  const renderErrors = () => {
    const { errors } = state;
    if (errors && errors.length >= 1) {
      return <ErrorsList errors={errors} />;
    }
  };
  const onSubmit = async e => {
    e.preventDefault();

    console.log(state);

    const { displayName, email, oldPassword, password, passwordConfirm } = state;
    const errors = [];

    if (displayName.length < 5 || displayName.length > 12) {
      errors.push('Display name must be between 6 and 12 characters.');
    }
    if (!validator.isEmail(email)) {
      errors.push('Please enter a valid email.');
    }
    if (oldPassword.length < 6 || oldPassword.length > 12) {
      errors.push('Password must be between 6 and 12 characters');
    }

    if (password.length < 6 || password.length > 12) {
      errors.push('Password must be between 6 and 12 characters');
    }
    if (!validator.equals(password, passwordConfirm)) {
      errors.push('Passwords must match');
    }

    if (errors.length >= 1) {
      setState({ ...state, errors });
    } else {
      try {
        await props.startChangePassword(email, oldPassword, password);
        //props.history.push('/');
      } catch (e) {
        const errors = [e];
        console.error("e", errors);
        //Object.keys(e).forEach(key => {
        //  errors.push(e[key].message);
        //});
        setState({ ...state, errors });
      }
    }
  };
  
    return (
      <div className="container card mt-4">
        <div className="row card-body">
          <div className="container col-lg-5">
            <div className='card-header'>
            <h3 className="mb-0 text-center">User Profile</h3>
            </div>
            {renderErrors()}
            <form onSubmit={onSubmit}>
              <div className="form-group">
                <label htmlFor="displayname">Display Name:</label>
                <input
                  className="form-control"
                  type="text"
                  readOnly="readonly"
                  name="displayname"
                  value={state.displayName}
                  placeholder="Enter a 6-12 character display name"
                  onChange={onDisplayNameChange}
                  autoFocus
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email:</label>
                <input
                  className="form-control"
                  type="text"
                  readOnly="readonly"
                  name="email"
                  value={state.email}
                  placeholder="Enter a valid email"
                  onChange={onEmailChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="password">Old Password:</label>
                <input
                  className="form-control"
                  type="password"
                  name="password"
                  placeholder="Enter a 6-12 character password"
                  onChange={onOldPasswordChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="password">Password:</label>
                <input
                  className="form-control"
                  type="password"
                  name="password"
                  placeholder="Enter a 6-12 character password"
                  onChange={onPasswordChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="passwordConfirm">Confirm Password:</label>
                <input
                  className="form-control"
                  type="password"
                  name="passwordConfirm"
                  placeholder="Re-enter your password"
                  onChange={onPasswordConfirmChange}
                />
              </div>
              <div className="form-group">
                <button className="btn btn-primary btn-block">Change Password</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  
}

const mapStateToProps = (state, props) => {
  console.log(state, props);

  return {
    auth: state.auth.user,
  }
};

const mapDispatchToProps = dispatch => ({
  startChangePassword: (email, oldPassword, password) =>
    dispatch(startChangePassword(email, oldPassword, password))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignupPage);
