import React, { useEffect, useState }  from "react";
import { connect } from "react-redux";
import { useNavigate } from 'react-router-dom';

import PostForm from "./PostForm";
import { startClearPosts, startAddPost } from "../actions/posts";

import { categoryUrl } from '../_helpers';

export const AddPostPage = (props) => {
  const [state, setState] = useState({error: ""});
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const renderError = () => {
    if (state.error) {
      return (
        <div className="error-msg alert alert-danger" role="alert">
          <p>{state.error}</p>
        </div>
      );
    }
  };
  const onSubmit = async post => {
    try {
      //console.log(post);
      await props.startAddPost(post);
      await props.startClearPosts();
      
      const groupUrl = categoryUrl(props.categories, post);
      navigate(groupUrl);
      
    } catch (e) {
      console.log(e);
      setState({ error: e.message });
    }
  };
  
  return (
    <div className={"card " + props.showAttr}>
      <div className="card-header pb-0">
        <h4 className="text-center">Add Post</h4>
      </div>
      {renderError()}
      <PostForm auth={props.auth} onSubmit={onSubmit} />
    </div>
  );
  
}

const mapStateToProps = state => ({
  auth: state.auth.user,
  categories: state.categories,
});

const mapDispatchToProps = dispatch => ({
  startAddPost: post => dispatch(startAddPost(post)),
  startClearPosts: () => dispatch(startClearPosts())  
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddPostPage);
