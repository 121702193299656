import React from "react";
import { connect } from "react-redux";
import { useState, useEffect } from 'react';
import { compose } from "redux";
import { useNavigate } from 'react-router-dom';

import SideBar from "./SideBar";
import PageHeader from "./PageHeader";
import BooksList from "./BooksList";
import { withRouter } from '../_helpers';
import { startSetPostsByCategory } from '../actions/posts';

import ReactPaginate from 'react-paginate';

export const ProductPage = (props) => {
  const [isLoading, setLoading] = useState(false); //State for the loading indicator
  const startLoading = () => setLoading(true);
  const stopLoading = () => setLoading(false);

  const [state, setState] = useState({error: ''});
  const navigate = useNavigate();

  //const posts = props.posts && props.posts[props.catId] ? props.posts[props.catId].result : [];

  //console.log("HomePage", props.currentPage, props.page, props, props.posts);

  useEffect(() => {
      async function fetchData() {
        try {
          if (!props.posts || props.currentPage !== props.page) {
            await props.startSetPostsByCategory(props.catId, props.postType, props.page);
          }
        } catch (e) {
          console.error(e);
          setState({ error: e.message });
        }
      }
      if (!state.error)
        fetchData();

      window.scrollTo(0, 0)
  }, [props]);

  const handleFetchStatus = () => {
    if (state.error) {
      return (
        <p className="text-monospace alert-danger text-center">
          {state.error}
        </p>
      );
    } else {
      return (
        <div className="loader-container">
          <p className="text-monospace loading-text text-center">
            Fetching posts...
          </p>
          <p className="text-monospace loading-text text-center">
            Please wait...
          </p>
        </div>
      );
    }
  };

  const pagginationHandler = (page) => {
    //console.log("page", page, props);

    const currentPath = props.router.basePath;
    const currentQuery = props.router.params;
    currentQuery.page = page.selected + 1;

    console.log(`${currentPath}`);

    if (currentPath === "/")
      navigate(`/home/${currentQuery.page}`)
    else
      navigate(`${currentPath}/${currentQuery.page}`)

    // navigate({
    //   pathname: currentPath,
    //   search: `?${createSearchParams({page: currentQuery.page})}`,
    // });
  };

  //console.log("catId", props.posts[catId]);

  return (
    <div>
        <PageHeader catId={props.catId} />

        <div className="content-detached">
          <div className="content-body">
            {props.posts && props.posts.length > 0 ? <BooksList catId={props.catId} posts={props.posts} /> 
              : handleFetchStatus()}
            <div className="d-flex justify-content-center">
            </div>            
          </div>
        </div>

    </div>
  );
};

//export default HomePage;

const mapStateToProps = (state, props) => {
  //console.log("state", state, props, props.router.params.page);
  const catId = props.catId;

  return {
    catId: catId,
    page: props.router.params.page ? parseInt(props.router.params.page) : 1,
    auth: state.auth.user,
    displayName: state.auth ? state.auth.user.displayName : "",
    totalCount: state.posts && state.posts.meta ? state.posts.meta.totalCount : 0,
    pageCount: state.posts && state.posts.meta ? state.posts.meta.pageCount : 0,
    currentPage: state.posts && state.posts.meta ? state.posts.meta.currentPage : 0,
    perPage: state.posts && state.posts.meta ? state.posts.meta.perPage : 0,
    posts: state.posts && state.posts.catId === catId ? state.posts.result : null
  }
};

const mapDispatchToProps = dispatch => ({
  startSetPostsByCategory: (id, type, page) => dispatch(startSetPostsByCategory(id, type, page))
});

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ProductPage);
