import React from "react";
import { connect } from "react-redux";
import { useEffect } from 'react';
import { startSetPostsByCategory } from '../actions/posts';

function Test(props) {
    console.log("test", props);

    useEffect(() => {
      // async function fetchData() {
      //   try {
      //     if (!props.posts || props.currentPage !== props.page) {
      //       await props.startSetPostsByCategory(props.catId, props.postType, props.page);
      //     }
      //   } catch (e) {
      //     console.error(e);
      //     //setState({ error: e.message });
      //   }
      // }
      // fetchData();

      window.scrollTo(0, 0)
    }, []);
      
    return (
      <div>
        xxx{props.posts.map(post => {
                    return (
                      <div key={post._id} className="card">
                        {post.title}
                          </div>
                      )})
            }
      </div>
    )
  }

const mapStateToProps = (state, props) => {
  console.log("state", state, props);
  const catId = 0;

  return {
    catId: catId,
    posts: state.posts.result
  }
};

export default connect(
  mapStateToProps,
    {startSetPostsByCategory}
)(Test);
  