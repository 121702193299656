import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { startGetCategories } from '../actions/posts';

export function SidebarComponent (props) {
    const [state, setState] = useState({error: ''});
    //const [menuItems, setMenuItem] = useState([]);

    useEffect(() => {
        async function fetchData() {
            try {
            if (state.error.length === 0 && (!props.categories || props.categories.length === 0)) {
                await props.startGetCategories();
            }
            } catch (e) {
                setState({ error: e.message });
            }
        }
        if (!state.error)
            fetchData();

    }, [props]);

    let menuItems = [];
    if (props.categories && props.categories.length > 0)
    {
        //console.log(props.categories);
        menuItems = props.categories
            .filter((linkConfig) => linkConfig)
            .map(({ icon, label, href, postCount, inmenu }) => {
                return (
                    (inmenu > 0) && <li key={href} className="nav-item">
                        <a className="nav-link" href={href}>
                            <span className={"fa mr-1 " + icon}></span>
                            {label} <span className="tag tag-success tag-pill">{postCount}</span>
                        </a>
                    </li>
                );
            });
    }

    return (
        <div className={props.showAttr}>
            <div className="sidebar">
                <div className="sidebar-content card hidden-md-down">
                    <div className="card-block">
                        <h4 className="box-title">Topics</h4>
                        <ul className='side-menu m-0'>
                            { menuItems }
                        </ul>
                    </div>
                </div>
            </div>
        </div>
  );
};

const mapStateToProps = state => ({
    categories: state.categories
});
  
const mapDispatchToProps = dispatch => ({
    startGetCategories: () => dispatch(startGetCategories())
});
  
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SidebarComponent);
  