import React from "react";
import { connect } from 'react-redux';
import moment from "moment";

export const PrintHeader = (props) => {
  
    return (
        <div className="row p-0 pb-1 mb-1 border-bottom">
            <span className="text-muted text-sm-left mb-0 col-md-4" style={{flex: "1 0"}}>
                <img src="/logo80.png" alt="Mindsponge" width="25" height="33" />&nbsp;
                <span className="d-xs-block d-md-inline-block">
                    Mindsponge
                </span>
            </span>
            <span className="text-muted text-sm-right mb-0 col-md-8" style={{flex: "1 0"}}>
                <span className="d-xs-block d-md-inline-block float-end ">
                    <a href="https://mindsponge.info/posts/{props.post._id}">https://mindsponge.info/posts/{props.post._id}</a> Last updated: {moment(props.post.createdAt).format('MM/DD/YYYY')}
                </span>
            </span>
        </div>
    );
}

const mapStateToProps = state => ({
  auth: state.auth.user
});

export default connect(mapStateToProps)(PrintHeader);
