import React from "react";
import { connect } from "react-redux";
import { useState, useEffect } from 'react';
import { compose } from "redux";
import { useNavigate, useSearchParams } from 'react-router-dom';

import SideBar from "./SideBar";
import PageHeader from "./PageHeader";
import PostsList from "./PostsList";
import CommentList from "./CommentList";
import AdsBanner from "./AdsBanner";
import { withRouter, validRole } from '../_helpers';
import { startSetPostsByTag } from '../actions/posts';

import ReactPaginate from 'react-paginate';

export const TagPage = (props) => {
  const [isLoading, setLoading] = useState(false); //State for the loading indicator
  const startLoading = () => setLoading(true);
  const stopLoading = () => setLoading(false);

  const [state, setState] = useState({error: ''});
  const navigate = useNavigate();

  let page = props.page;

  const [searchParams] = useSearchParams();
  for (const entry of searchParams.entries()) {
    const [param, value] = entry;
    //const currentParams = Object.fromEntries([...searchParams]);
    if (param === 'page')
      page = parseInt(value);
  }
  page = page > props.pageCount ? props.pageCount : page;
  page = page < 1 ? 1 : page;

  //const tag = props.router.params.tag;

  //const posts = props.posts && props.posts[props.catId] ? props.posts[props.catId].result : [];

  //console.log("TagPage", props.currentPage, props.page, page, props, props.posts, (props.posts == null || props.currentPage !== page));

  useEffect(() => {
      async function fetchData() {
        try {
          if (props.posts == null || props.currentPage !== page || (props.preload && validRole(props.auth,"editor"))) {
            startLoading();
            await props.startSetPostsByTag(props.tag, page);
          }
        } catch (e) {
          console.error(e);
          setState({ error: e.message });
        }
      }
      if (!state.error)
        fetchData();

      window.scrollTo(0, 0);
  }, [props, page]);

  const handleFetchStatus = () => {
    if (state.error) {
      return (
        <p className="text-monospace alert-danger text-center">
          {state.error}
        </p>
      );
    } else {
      return (
        <div className="loader-container">
          <p className="text-monospace loading-text text-center">
            Fetching posts...
          </p>
          <p className="text-monospace loading-text text-center">
            Please wait...
          </p>
        </div>
      );
    }
  };

  const pagginationHandler = (page) => {
    //console.log("page", page, props);

    const currentPath = props.router.basePath;
    const currentQuery = props.router.params;
    currentQuery.page = page.selected + 1;

    //console.log(`${currentPath}`);

    navigate(`${currentPath}/${props.tag}/${currentQuery.page}`)

    // navigate({
    //   pathname: currentPath,
    //   search: `?${createSearchParams({page: currentQuery.page})}`,
    // });
  };

  //console.log("catId", props.posts[catId]);

  return (
    <div>
        <PageHeader tag={props.tag} />

        <div className="content-detached content-left">
          <div className="content-body">
            {props.posts && props.posts.length > 0 ? <PostsList catId={props.catId} posts={props.posts} /> 
              : handleFetchStatus()}
            <div className="d-flex justify-content-center">
            {(props.pageCount > 1) && <ReactPaginate
              previousLabel={"«"}
              nextLabel={"»"}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={props.pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={pagginationHandler}
              containerClassName={'pagination'}
              pageClassName="page-item"
              previousClassName="page-item"
              nextClassName="page-item"
              pageLinkClassName="page-link"
              previousLinkClassName="page-link"
              nextLinkClassName="page-link"
              activeClassName="active"
              forcePage={page-1}
              //initialSelected={props.pageCount}
            />}
            </div>            
          </div>
        </div>
        <div className="sidebar-detached sidebar-right">
          <SideBar showAttr="d-none d-md-block" />
          <CommentList />

          <AdsBanner Link="/posts/101" Image="/images/bmf.webp" Title="" />

          <AdsBanner Link="/posts/98" Image="/images/kingfisher.webp" Title="" />
        </div>
    </div>
  );
};

//export default HomePage;

const mapStateToProps = (state, props) => {
  //console.log("state", state, props, props.router.params.page);
  const tag = props.router.params.tag;

  return {
    tag: tag,
    page: props.router.params.page ? parseInt(props.router.params.page) : 1,
    auth: state.auth.user,
    totalCount: state.posts && state.posts.meta ? state.posts.meta.totalCount : 0,
    pageCount: state.posts && state.posts.meta ? state.posts.meta.pageCount : 0,
    currentPage: state.posts && state.posts.meta ? state.posts.meta.currentPage : 0,
    perPage: state.posts && state.posts.meta ? state.posts.meta.perPage : 0,
    posts: state.posts && state.posts.tag === tag ? state.posts.result : null,
    preload: state.posts.preload
  }
};

const mapDispatchToProps = dispatch => ({
  startSetPostsByTag: (tag, page) => dispatch(startSetPostsByTag(tag, page))
});

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(TagPage);
