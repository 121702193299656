import React, {useEffect} from "react";
import { connect } from "react-redux";
import moment from "moment";

import { startSetMostReadPosts } from '../actions/posts';

export const PostsReads = (props) => {
  const [editing, setEditing] = React.useState(0);
  const [state, setState] = React.useState({ id: 0, title: "", body: "", error: "" });

  useEffect(() => {
    try {
      const fetchPosts = async () => {
        await props.startSetMostReadPosts(0);
      }

      if (!state.error && !props.posts)
      fetchPosts()
          .catch(console.error);

    } catch (e) {
      console.error(e);
      setState({ ...state, error: e.message });
    }

  }, [props]);

  const renderError = () => {
    if (state.error) {
      return <div className="error-msg text-danger">{state.error}</div>;
    }
  };

  const renderPosts = () => {
    if (props.posts && props.posts.result.length > 0) {
      return props.posts.result.map(post => {
        return (
          (post._id > 0) && <li key={post._id} className="nav-item">
                        <a className={`nav-link`} href={`/posts/${post._id}`}>
                            {post.title} - <span className="text-muted" style={{fontFamily: "Open Sans, sans-serif", fontSize:"10pt"}}>
                              {post.author} ({moment(post.createdAt).format('MM/DD/YYYY')})</span>
                        </a>
                    </li>  
        );
      });
    } else {
      return <p>No post found!</p>;
    }
  };
  
    return (
      <div className={"sidebar-detached "}>
            <div className="sidebar">
                <div className="sidebar-content card hidden-md-down">
                    <div className="card-block">
                        <div id="sidebar" className="category-title">
                        <h4 className="box-title">Most-read</h4>
                        <ul className='side-menu m-0'>
                          {renderPosts()}
                        </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
  
}

const mapStateToProps = (state, props) => ({
  auth: state.auth.user,
  posts: state.mostreads ? state.mostreads : null,
});

export default connect(mapStateToProps,
  {startSetMostReadPosts}
)(PostsReads);
