import axios from "axios";
import { store } from '../store/configureStore';
import { authActions } from '../reducers/auth';
import { authToken, authHeader } from './auth-helper';

export const axiosWrapper = {
    get: request('GET'),
    post: request('POST'),
    put: request('PUT'),
    delete: request('DELETE')
};

function request(method) {
    return (url, body) => {
        //console.log("url", url);

        return axios({ method: method, url: url, data: body, headers: authHeader(url) }).then(handleResponse);
    }
}

function handleResponse(response) {
    //console.log("response", response);
    const data = response.data;

    if (response.status !== 200) {
        if ([401, 403].includes(response.status) && authToken()) {
            // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
            const logout = () => store.dispatch(authActions.logout());
            logout();
        }

        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
    }

    //console.log(response.headers["x-auth"]);
    // if the auth header exists
    if (response.headers["x-auth"])
    {
        data["token"] = response.headers["x-auth"];
    }
    //console.log(data);
    return data;
}