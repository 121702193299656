import React, { useState } from "react";
import { compose } from "redux";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

import { startClearPosts, startPublishPost, startUnPublishPost, startDeletePost } from '../actions/posts';

import { withRouter, validRole } from '../_helpers';

export function BooksList (props) {
  const [state, setState] = useState({error: ''});
  const navigate = useNavigate();
  //console.log("props", props);

  const handleFetchStatus = () => {
    if (state.error) {
      return (
        <p className="text-monospace alert-danger text-center">
          {state.error}
        </p>
      );
    } else {
      return (
        <div className="loader-container">
          <p className="text-monospace loading-text text-center">
            ...
          </p>
        </div>
      );
    }
  };
  const renderThumbnail = (post) => {
    if (post.thumbnail) {
      return <img src={post.thumbnail} className="product-topbook-image" alt={`${post.title}`} />;
    }
  }

  const doDelete = async (e, post) => {
    const id = post._id;
    await props.startDeletePost(id);
    await props.startClearPosts();
  
  };

  const doPublish = async (e, post) => {
    const id = post._id;
    await props.startPublishPost(id);
    await props.startClearPosts();
  
  };

  const doUnPublish = async (e, post) => {
    const id = post._id;
    await props.startUnPublishPost(id);
    await props.startClearPosts();
  
  };

  const doEdit = (e, post) =>
  {
    navigate(`/posts/${post._id}/edit`);
  };
  
  return (
    <div>
        <div className="row">
          <div className="col">
            <div className="card-columns">
              {(props.posts && props.posts.length >= 1) && 
              <div key={props.posts[0]._id} className="card">
                        <div className="card-body">
                          <div className="row-sapo">
                          <div className="product-topbook">
                            {renderThumbnail(props.posts[0])}
                          </div>
                          <div className="sapo">
                          <h2 className="article-title">
                            {((props.posts[0].status === 0 && validRole(props.auth,"editor")) || validRole(props.auth,"admin")) && <ul className="nav navbar-nav float-xs-right">
                                  {(props.posts[0].status === 0 && validRole(props.auth,"admin")) && <li className="nav-item">
                                      <button onClick={(e) => doPublish(e, props.posts[0])} className="btn btn-outline-dark">
                                          <i className="fa fa-upload" /> 
                                      </button>
                                  </li>}
                                  {(props.posts[0].status === 1 && validRole(props.auth,"admin")) && <li className="nav-item">
                                      <button onClick={(e) => doUnPublish(e, props.posts[0])} className="btn btn-outline-dark">
                                          <i className="fa fa-download" /> 
                                      </button>
                                  </li>}
                                  <li className="nav-item">
                                      <button onClick={(e) => doEdit(e, props.posts[0])} className="btn btn-outline-dark">
                                          <i className="fa fa-pencil-square-o" />
                                      </button>
                                  </li>
                                  <li className="nav-item">
                                      <button onClick={async (e) => await doDelete(e, props.posts[0])} className="btn btn-outline-dark">
                                          <i className="fa fa-trash-o" />
                                      </button>
                                  </li>
                                </ul>
                              }
                              {(props.posts[0].status === 0) && <span style={{fontSize: "20pt"}}>[DRAFT] </span>}
                              <Link to={`/posts/${props.posts[0]._id}`} className="title" rel="post">
                                {props.posts[0].title}
                              </Link>
                            </h2>
                              {props.posts[0].sapo.substring(0, 1500) + '...'}
                              <div className="text-xs-right btn-read position-absolute bottom-0 end-0">
                              <Link
                                to={`/posts/${props.posts[0]._id}`}
                                key={props.posts[0]._id}
                                className="btn btn-outline-primary ">
                                VIEW BOOK
                              </Link>
                              </div>
                          </div>

                          </div>
                          <span className="badge badge-info">
                            
                          </span>
                        </div>
              </div>
                  }
            </div>
          </div>
        </div>

        <div className="product-books">
              {props.posts && props.posts.length >= 1
                ? props.posts.map(post => {
                    return (
                      (post.status === 1 || validRole(props.auth,"editor")) && 
                      <div className="col" key={post._id}>

                          <div className="product-book">
                            <img src={post.thumbnail} className="product-book-image" alt={`${post.title}`} />
                            <div className="d-grid gap-2 mt-1">
                            <a
                                href={`/posts/${post._id}`}
                                className="btn btn-outline-primary "                              >
                                VIEW BOOK
                              </a>
                              </div>

                          </div>
                      </div>                      
                    );
                  })
                : handleFetchStatus()}

{props.posts && props.posts.length >= 1
                ? props.posts.map(post => {
                    return (
                      (post.status === 1 || validRole(props.auth,"editor")) && 
                      <div className="col" key={post._id}>

                          <div className="product-book">
                            <img src={post.thumbnail} className="product-book-image" alt={`${post.title}`} />
                            <div className="d-grid gap-2 mt-1">
                            <a
                                href={`/posts/${post._id}`}
                                className="btn btn-outline-primary "                              >
                                VIEW BOOK
                              </a>
                              </div>

                          </div>
                      </div>                      
                    );
                  })
                : handleFetchStatus()}
        </div>
    </div>
    );
  
}

const mapStateToProps = (state, props) => ({
  auth: state.auth.user,
  categories: state.categories,
  page: props.router.params.page ? parseInt(props.router.params.page) : 1,
  //posts: !props.posts && state.posts && state.posts[props.catId]? updatePostCategory(state.posts[props.catId].result) : []
});

const mapDispatchToProps = dispatch => ({
  startDeletePost: (id) => dispatch(startDeletePost(id)),
  startPublishPost: (id) => dispatch(startPublishPost(id)),
  startUnPublishPost: (id) => dispatch(startUnPublishPost(id)),
  startClearPosts: () => dispatch(startClearPosts()),  
});

export default compose(
  withRouter,
  connect(
  mapStateToProps,
  mapDispatchToProps)
)(BooksList);
