import { axiosClient } from '../_helpers';

export const setComments = (comments) => ({
  type: "SET_COMMENTS",
  comments
});

export const setCommentList = (comments) => ({
  type: "SET_COMMENTLIST",
  comments
});

export const clearComments = posts => ({
  type: "CLEAR_COMMENTS",
  posts
});

export const startClearComments = () => {
  //console.log("startClearComments");

  return async dispatch => {
    try {
      dispatch(clearComments());
    } catch (e) {
      console.error(e.message);
      throw new Error("Could not clear comments...");
    }
  };
};

export const startSetComments = (id, page) => {
  return async dispatch => {
    try {
      const response = await axiosClient.post(
        `${process.env.REACT_APP_API_URL}/comments`,
        {postId: id, page: page}
      );
      //console.log("startSetComments", id, response.data);
      if (response.data)
      {
        if (id > 0)
        {
          dispatch(setComments({postId: id, comments: response.data}));
        }
        else
        {
          dispatch(setCommentList({commentList: response.data}));
        }
      }
      else
        throw new Error("Could not retrieve comments...");
    } catch (e) {
      console.error(e.message);
      throw new Error("Could not retrieve comments...");
    }
  };
};

export const addComment = (id, comment) => ({
  type: "ADD_COMMENT",
  comment
});

export const startAddComment = (comment) => {
  return async dispatch => {
    try {
      const { token } = JSON.parse(localStorage.getItem("auth"));
      const response = await axiosClient({
        url: `${process.env.REACT_APP_API_URL}/comment`,
        method: "post",
        headers: {
          "x-auth": token
        },
        data: comment        
      });

      dispatch(addComment(response.data));
      dispatch(clearComments());
    } catch (e) {
      console.error(e.message);
      throw new Error(e.response.data.error);
    }
  };
};

export const deleteComment = id => ({
  type: "DELETE_COMMENT",
  id
});

export const startDeleteComment = (id) => {
  return async dispatch => {
    console.log(id);

    const { token } = JSON.parse(localStorage.getItem("auth"));
    try {
      const response = await axiosClient({
        url: `${process.env.REACT_APP_API_URL}/comment/${id}`,
        method: "delete",
        headers: {
          "x-auth": token
        }
      });

      if (response.data)
      {
        dispatch(deleteComment(id));
        dispatch(clearComments());
      }
    } catch (e) {
      console.error(e);
      //throw new Error(e.response.data.error);
    }
  };
};

export const publishComment = id => ({
  type: "PUBLISH_COMMENT",
  id
});

export const startPublishComment = (id) => {
  return async dispatch => {
    console.log(id);

    const { token } = JSON.parse(localStorage.getItem("auth"));
    try {
      const response = await axiosClient({
        url: `${process.env.REACT_APP_API_URL}/comment/pub/${id}`,
        method: "post",
        headers: {
          "x-auth": token
        }
      });

      if (response.data)
      {
        dispatch(publishComment(id));
        dispatch(clearComments());
      }
    } catch (e) {
      console.error(e);
      //throw new Error(e.response.data.error);
    }
  };
};

export const startUnPublishComment = (id) => {
  return async dispatch => {
    console.log(id);

    const { token } = JSON.parse(localStorage.getItem("auth"));
    try {
      const response = await axiosClient({
        url: `${process.env.REACT_APP_API_URL}/comment/unpub/${id}`,
        method: "post",
        headers: {
          "x-auth": token
        }
      });

      if (response.data)
        dispatch(publishComment(id));
    } catch (e) {
      console.error(e);
      //throw new Error(e.response.data.error);
    }
  };
};
