import React, {useState} from 'react';
import Select from 'react-select';
import { Button, Modal } from 'react-bootstrap';
import ErrorsList from './ErrorsList';
import Thumbnail from './Thumbnail';
import Resizer from "react-image-file-resizer";
import {string2tags, tags2string} from "../_helpers";
import { validRole } from '../_helpers';

const PostAddNew = (props) => {
  const [state, setState] = React.useState({
    _id: props.post ? props.post._id : 0,
    author: props.post ? props.post.author : '',
    authorEmail: props.post ? props.post.authorEmail : '',
    title: props.post ? props.post.title : '',
    sapo: props.post ? props.post.sapo : '',
    createdAt: props.post ? props.post.createdAt : Date.now(),
    category: props.post ? props.post.category : '',
    postType: props.post ? props.post.postType : 0,
    groupId: props.post ? props.post.groupId : props.catId,
    tags: props.post ? props.post.tags : [],
    mainImage: props.post ? props.post.mainImage : '',
    thumbnail: props.post ? props.post.thumbnail : '',
    body: props.post ? props.post.body : '',
    createdBy: props.auth ? props.auth._id : 0,
    errors: ''
  });

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [saving, setSaving] = useState(false);

  React.useEffect(() => {
    setState({
      _id: props.post ? props.post._id : 0,
      author: props.post ? props.post.author : '',
      authorEmail: props.post ? props.post.authorEmail : '',
      title: props.post ? props.post.title : '',
      sapo: props.post ? props.post.sapo : '',
      createdAt: props.post ? props.post.createdAt : Date.now(),
      category: props.post ? props.post.category : '',
      postType: props.post ? props.post.postType : 0,
      groupId: props.post ? props.post.groupId : 0,
      tags: props.post ? props.post.tags : [],
      mainImage: props.post ? props.post.mainImage : '',
      thumbnail: props.post ? props.post.thumbnail : '',
      body: props.post ? props.post.body : '',
      createdBy: props.auth ? props.auth._id : 0,
      errors: ''
    });

  }, [props])

  const catOptions = props.categories ? props.categories.filter(cat => { if (cat.value > 0) return cat; }) : [];

  const onAuthorChange = e => {
    setState({...state, author: e.target.value });
  };
  const onEmailChange = e => {
    setState({...state, authorEmail: e.target.value });
  };
  const onTitleChange = e => {
    setState({...state, title: e.target.value });
  };
  const onCategoryChange = e => {
    setState({...state,  groupId: e.value });
    //setState({...state,  category: e.label });
    //console.log(state, e.value);
  };
  const onPostTypeChange = e => {
    setState({...state,  postType: e.value });
  };
  const onSapoChange = e => {
    setState({...state,  sapo: e.target.value });
  };
  const onTagsChange = e => {
    setState({...state,  tags: string2tags(e.target.value) });
  };
  const resizeFile = (file) => new Promise(resolve => {
    Resizer.imageFileResizer(file, 230, 160, 'JPEG', 100, 0,
    uri => {
      resolve(uri);
    }, 'base64' );
  });
  const onThumbnailChange = async (e) => {
    const file = e.target.files[0];
    const image = await resizeFile(file);
    
    setState({...state,  thumbnail: image });
  };
  const renderErrors = () => {
    const { errors } = state;
    if (errors) {
      return <ErrorsList errors={errors} />;
    }
  };

  const onSubmit = e => {
    e.preventDefault();

    //console.log(props.auth);
    setSaving(true);

    const { title, sapo } = state;
    //let body = editorState.getCurrentContent().getPlainText('\u0001');

    const errors = [];
    if (!title || title.length < 4) {
      errors.push('Title should have at least 4 characters.');
    }
    if (!sapo || sapo.length < 4) {
      errors.push('Sapo should have at least 4 characters');
    }
    
    if (errors.length >= 1) {
      setState({...state, errors });
    } else {
      const post = state;
      props.onSubmit(post);
    }

    setSaving(false);
  };

  return (
    validRole(props.auth, "editor") && <div>
      <div className='top-tools'>
        <button onClick={() => handleShow()} className="btn btn-outline-success">
          <i className="fa fa-plus-square-o" /> New Post
        </button>
      </div>

    <Modal show={show} onHide={handleClose} size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered>
      <Modal.Header closeButton>
        <Modal.Title>Add New Post</Modal.Title>
      </Modal.Header>
      <form onSubmit={onSubmit}>
      <Modal.Body>
      {renderErrors()}
      {saving && <div>Data saving in process... please wait!</div>}
      
        <div className="row">
          <div className="col">
            <label className="form-label">Author</label>
            <input
            type="text"
            className="form-control"
            name="author"
            onChange={onAuthorChange}
            value={state.author}
            maxLength={60}
          />
          </div>
          <div className="col">
          <label className="form-label">Author Email</label>
          <input
            type="email"
            className="form-control"
            name="email"
            onChange={onEmailChange}
            value={state.authorEmail}
            maxLength={60}
          />
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="title">Title</label>
          <input
            type="text"
            className="form-control"
            name="title"
            onChange={onTitleChange}
            value={state.title}
            maxLength={160}
          />
        </div>

        <div className="row">
          <div className="col">
            <label className="form-label">Category</label>
            <Select
              value = {
                catOptions.filter(option => 
                    option.value === state.groupId)
              }
              onChange = {onCategoryChange}
              options={catOptions}
              placeholder={"Select a category"}
            />
          </div>
        </div>
        <div className="form-group">
            <label>Sapo</label>
            <textarea
              className="form-control"
              id="sapo"
              name="sapo"
              rows="4"
              onChange={onSapoChange}
              value={state.sapo}
            />
        </div>
        <div className="row">
          <div className="col-md-8">
          <label htmlFor="thumbnail">Image Thumbnail (Optional)</label>
            <input
              id="avatar"
              className="form-control"
              name="thumbnail"
              type="file"
              accept=".jpg,.gif,.png"
              onChange={onThumbnailChange}
              placeholder="Enter URL for the dashboard card image"
            />
          </div>
          <div className='col-md-4'>
            <Thumbnail imageSrc={state.thumbnail} />
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="tags">Tags (Separate by ;)</label>
          <input
            type="text"
            className="form-control"
            name="tags"
            onChange={onTagsChange}
            value={tags2string(state.tags)}
            maxLength={160}
          />
        </div>

      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose} disabled={saving}>
          Close
        </Button>
        <Button variant="primary" type="submit">
          Save
        </Button>
      </Modal.Footer>
      </form>          
    </Modal>

  </div>

  );
  
}

export default PostAddNew;
