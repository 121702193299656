import { combineReducers, applyMiddleware, compose } from "redux";
import { configureStore } from '@reduxjs/toolkit';
import thunk from "redux-thunk";

import postReducer from "../reducers/post";
import trackingReducer from "../reducers/tracking";
import postsReducer from "../reducers/posts";
import mostreadsReducer from "../reducers/mostreads";
import commentsReducer from "../reducers/comments";
import categoriesReducer from "../reducers/categories";
import {authReducer} from "../reducers/auth";

const preloadedState = window.__PRELOADED_STATE__;
delete window.__PRELOADED_STATE__;

//console.log("preloadedState", preloadedState);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducers = combineReducers({
  tracking: trackingReducer,
  post: postReducer,
  posts: postsReducer,
  mostreads: mostreadsReducer,
  comments: commentsReducer,
  categories: categoriesReducer,
  auth: authReducer
});

export const store = configureStore({reducer:reducers, preloadedState:preloadedState}, composeEnhancers(applyMiddleware(thunk)));

